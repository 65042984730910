import { FC } from "react";
import styles from "./styles.module.css";
import Group from "../Group";
import { StarIcon, StarFilledIcon } from "@radix-ui/react-icons";

interface Props {
  rating: 0 | 1 | 2 | 3 | 4 | 5;
}

const Rating: FC<Props> = ({ rating }) => {
  const starColor = "var(--color-brand-aqua-100)";

  return (
    <Group gap="2px">
      {Array.from({ length: rating }, (_, index) => (
        <StarFilledIcon key={index} color={starColor} />
      ))}
      {Array.from({ length: 5 - rating }, (_, index) => (
        <StarIcon key={index} color={starColor} />
      ))}
    </Group>
  );
};

export default Rating;
