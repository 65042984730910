import React, { InputHTMLAttributes } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import type { RadioGroupProps } from "@radix-ui/react-radio-group";
import styles from "./styles.module.css";
import Group from "../../atoms/Group";
import Text from "../../atoms/Text";
import Stack from "../../atoms/Stack";

interface Option {
  value: string;
  label: string;
}

interface Props extends RadioGroupProps {
  title?: string;
  name: string;
  options: Option[];
  inlineTitle?: boolean;
}

const RadioButtonGroup = (props: Props) =>
  props.title ? (
    <RadioButtonGroupWithTitle {...props} />
  ) : (
    <RadioGroup.Root className={styles.root} defaultValue={props.defaultValue} onValueChange={props.onValueChange}>
      {props.options.map((option, index) => (
        <div style={{ display: "flex", alignItems: "center" }} key={index}>
          <RadioGroup.Item className={styles.item} value={option.value} id={`${props.name}-${index}`}>
            <RadioGroup.Indicator className={styles.indicator} />
          </RadioGroup.Item>
          <label className={styles.label} htmlFor={`${props.name}-${index}`}>
            {option.label}
          </label>
        </div>
      ))}
    </RadioGroup.Root>
  );

const RadioButtonGroupWithTitle = ({ title, ...props }: Props) => {
  return props.inlineTitle ? (
    <Group grow={true} justify="space-between" gap="24px">
      <Text color="secondary">{title}</Text>
      <RadioButtonGroup {...props} />
    </Group>
  ) : (
    <Stack gap="12px">
      <Text color="secondary">{title}</Text>
      <RadioButtonGroup {...props} />
    </Stack>
  );
};

export default RadioButtonGroup;
