import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import Grid from "../../../Grid/Grid";
import Textfield from "../../../TextField/TextField";
import CheckBox from "../../../Checkbox/Checkbox";
import Dropdown from "../../../Dropdown/Dropdown";
import UserContext from "../../../../contexts/UserContext";
import Spinner from "../../../Spinner/Spinner";
import PhoneField from "../../../PhoneInput/PhoneInput";

import { ErrorDialogContext } from "../../../../contexts/ErrorDialogContext";
import { getLanguages } from "../../../../services/RegisterCompany";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { getUserById } from "../../../../services/Login";
import { updateUser } from "../../../../services/Settings";
import { validateFieldsHelper } from "../../../../utils/ValidationHelper";
import { accountSettingsSchema } from "./validation/AccountSettingsValidationSchemas";
import Heading from "../../atoms/Heading";
import Text from "../../atoms/Text";

import { SettingsFormContent, SettingsFormCTAs } from ".";
import CTA from "../../atoms/CTA";
import Divider from "../../atoms/Divider";
import { Language, User, ValidationErrors } from "types";

interface Props {
  changeLocale: (locale: any, i18n: any, timeZone: any) => void;
}

export default function AccountSettings(props: Props) {
  const { i18n, t } = useTranslation();
  const { changeLocale } = props;

  const errorDialogContext: any = useContext(ErrorDialogContext);
  const { showErrorDialog = () => {}, setErrorDialogOptions = () => {} } = errorDialogContext || {};
  const userContext = useContext(UserContext);
  const { user, currentCompany } = userContext;

  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loadingSave, setLoadingSave] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    async function asyncGetUser() {
      const response = await getUserById(user.id);
      setCurrentUser(response.data);
    }

    // Language dropdown
    async function loadLanguages() {
      const response = await getLanguages();
      let languageOptions = response.data;

      const _languageOptions = languageOptions.languages.map((language: Language) => ({
        value: language.id,
        label: language.name,
        languageCode: language.languageCode,
      }));
      setLanguageOptions(_languageOptions);
    }

    loadLanguages();
    asyncGetUser();
  }, [user.id]);

  useEffect(() => {
    // prefill language
    const language = languageOptions.find((option: any) => option.value === get(user, "language.id"));
    language && setSelectedLanguage(language);
  }, [user, languageOptions]);

  function onChange(change: any) {
    setCurrentUser({
      ...currentUser,
      ...change,
    });
  }

  function onChangeLanguage(e: any) {
    setSelectedLanguage(e);
    changeLocale(e.languageCode, i18n, currentCompany && currentCompany.companyTimeZone);
    const language = e;
    onChange({
      language: {
        id: language.value,
        name: language.label,
        languageCode: language.languageCode,
      },
    });
  }

  const saveHandler = async () => {
    try {
      setLoadingSave(true);
      const inputChecker: any = validateFieldsHelper(accountSettingsSchema(t), currentUser);
      setValidationErrors(inputChecker);
      if (Object.keys(inputChecker).length === 0) {
        setValidationErrors({});
        currentUser &&
          (await updateUser(
            currentUser.id,
            currentUser.firstname,
            currentUser.lastname,
            currentUser.email,
            currentUser.phone,
            currentUser.language.id,
            currentUser.receiveCopyOfTradingNotifications
          ));
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Update user feilet.", error });
      showErrorDialog(true);
    }
    setLoadingSave(false);
  };

  return (
    <>
      <Heading order={2} styleOrder={4} tt="uppercase">
        {t("settings.youraccount")}
      </Heading>
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="md" strong={true} display="technical" tt="uppercase">
                {t("common.details")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="sm" strong={true} display="technical" tt="uppercase">
                {t("common.user")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Textfield
                id="account-firstname"
                label={t("common.firstname")}
                value={get(currentUser, "firstname", "")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ firstname: e.target.value })}
                errormessage={validationErrors.firstname}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Textfield
                id="account-lastname"
                label={t("common.lastname")}
                value={get(currentUser, "lastname", "")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ lastname: e.target.value })}
                errormessage={validationErrors.lastname}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Textfield
                id="account-email"
                label={t("common.email")}
                disabled={true}
                value={get(currentUser, "email", "")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ email: e.target.value })}
                errormessage={validationErrors.email}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <PhoneField
                id="account-phone"
                value={get(currentUser, "phone")}
                placeholder={t("common.phone")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ phone: e })}
                errormessage={validationErrors.phone}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <CheckBox
                id="receiveCopyOfTradingNotifications"
                label={t("common.receiveCopyOfTradingNotifications")}
                checked={get(currentUser, "receiveCopyOfTradingNotifications", false)}
                onChange={(e) => onChange({ receiveCopyOfTradingNotifications: e.checked })}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Text size="sm" strong={true} display="technical" tt="uppercase">
              {t("common.language")}
            </Text>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Dropdown
                id="account-language"
                label={t("settings.languageGUI")}
                options={languageOptions}
                onChange={onChangeLanguage}
                value={selectedLanguage}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>
      <SettingsFormCTAs>
        <CTA size="lg" id="account-save-language" intent="primary" onClick={saveHandler}>
          {t("common.save")}
        </CTA>
        <Spinner loading={loadingSave} inline={true} />
      </SettingsFormCTAs>
    </>
  );
}
