import { FC } from "react";
import clsx from "clsx";
import styles from "./styles.module.css";
import { ContractTradeType } from "@/types";

interface Props {
  contractTradeType: ContractTradeType;
  percent: number;
  size?: "xs";
}

const Progress: FC<Props> = ({ contractTradeType, percent, size }) => {
  const classes = clsx(styles.progressRoot, {
    [styles.tableDisplay]: size === "xs",
  });

  return (
    <div className={classes}>
      <span className={`${styles.percent} ${styles[contractTradeType]}`} style={{ width: `${percent}%` }} />
    </div>
  );
};

export default Progress;
