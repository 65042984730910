import { FC, ReactNode } from "react";
import styles from "./styles.module.css";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import Text from "../../atoms/Text";
import Group from "../../atoms/Group";
import ContextPanel from "@/components/ContextPanel/ContextPanel";
import { TableIconLocked } from "../../atoms/Icons/SettingsTableIcons";
import CTA from "../../atoms/CTA";

interface Props {
  tableHeaders: string[];
  tableRows: ReactNode[][];
  accordionTriggerContentIndex?: number;
  createEditActions: (index: number) => { label: string; action: () => void }[];
  isAdmin: boolean;
}

const SettingsTable: FC<Props> = ({
  tableHeaders,
  tableRows,
  accordionTriggerContentIndex,
  createEditActions,
  isAdmin,
}) => {
  const filterMobileCells = (i: number) => !accordionTriggerContentIndex;

  return (
    <div className={styles.settingsTableRoot}>
      <table className={styles.desktopTable}>
        <thead>
          <tr>
            {tableHeaders.map((th, i) => (
              <th key={"th" + i}>{th}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableRows.map((tr, i) => (
            <tr key={i}>
              {tr.map((td, j) => (
                <td key={"td" + j}>{td}</td>
              ))}
              <td key={"td" + tr.length}>
                {isAdmin ? <ContextPanel options={createEditActions(i)} /> : <TableIconLocked />}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Accordion.Root type="single" defaultValue="item-0" collapsible className={styles.mobileAccordions}>
        {tableRows.map((tr, i, arr) => {
          const editActions = createEditActions(i);

          return (
            <Accordion.Item value={"item-" + i} key={i} className={styles.accordionItem}>
              <AccordionTrigger className={styles.accordionTrigger}>
                <Group justify="space-between" className={styles.triggerContent}>
                  <Group>
                    <Text size="sm" display="technical" tt="uppercase" strong={true}>
                      {accordionTriggerContentIndex
                        ? tr[accordionTriggerContentIndex]
                        : (i + 1).toString().padStart(2, "0")}
                    </Text>
                  </Group>
                </Group>
              </AccordionTrigger>

              <Accordion.Content className={styles.accordionContent}>
                <ul className={styles.mobileTableRow}>
                  {tr
                    .filter((_, j) => filterMobileCells(j))
                    .map((td, j) => (
                      <li className={styles.mobileTableCell} key={j}>
                        <Group justify="space-between">
                          <Text span={true} size="sm" display="technical" tt="uppercase" strong={true} key={"th" + j}>
                            {tableHeaders.filter((_, i) => filterMobileCells(i))[j]}
                          </Text>
                          <Text span={true} key={"td" + j}>
                            {td}
                          </Text>
                        </Group>
                      </li>
                    ))}
                  <li className={styles.mobileTableCell}>
                    <Group gap="8px" className={styles.mobileCtas}>
                      {editActions.map((action, i) => (
                        <CTA onClick={action.action} intent="secondary" key={i}>
                          {action.label}
                        </CTA>
                      ))}
                    </Group>
                  </li>
                </ul>
              </Accordion.Content>
            </Accordion.Item>
          );
        })}
      </Accordion.Root>
    </div>
  );
};

const AccordionTrigger = ({ children, className }: any) => (
  <Accordion.Header>
    <Accordion.Trigger className={className}>
      {children}
      <ChevronDownIcon aria-hidden className={styles.toggleIcon} />
    </Accordion.Trigger>
  </Accordion.Header>
);

export default SettingsTable;
