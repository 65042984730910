import { FC, PropsWithChildren } from "react";
import { Title as MantineTitle, TitleProps as MantineTitleProps } from "@mantine/core";
import styles from "./styles.module.css";

type TitleOrder = 1 | 2 | 3 | 4 | 5 | 6;

export interface HeadingProps extends MantineTitleProps {
  order: TitleOrder;
  styleOrder?: TitleOrder;
  strong?: boolean;
}

const Heading: FC<PropsWithChildren<HeadingProps>> = ({ order, styleOrder, lineClamp, textWrap, strong, ...props }) => {
  const headingStyleOrder = !styleOrder ? order : styleOrder;

  return (
    <MantineTitle
      {...props}
      order={order}
      className={styles.headingRoot}
      vars={() => ({
        root: {
          ...(lineClamp ? { "--title-line-clamp": `${lineClamp}` } : {}),
          ...(textWrap ? { "--title-text-wrap": `${textWrap}` } : {}),
          "--font-styles": `var(--text-typography-heading-${headingStyleOrder}-${strong ? "strong" : "default"})`,
        },
      })}
      lineClamp={lineClamp}
    >
      {props.children}
    </MantineTitle>
  );
};

export default Heading;
