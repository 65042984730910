import React, { useState, useEffect, useContext, useCallback, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { getCreateOrderValues, getValidConditions, getValidTypes } from "@/services/Orders";
import { getCompanyById, getCompanySpecificProducts } from "@/services/Company";

import amountDeviationOptionValues from "@/constants/AmountDeviationValues";
import UserContext from "@/contexts/UserContext";
import SortDropDownOptions from "@/utils/SortDropDownOptions";
import Grid from "@/components/Grid/Grid";
import Dropdown from "@/components/Dropdown/Dropdown";
import TextField from "@/components/TextField/TextField";
import TextfieldDropdown from "@/components/TextfieldDropdown/TextfieldDropdown";
import CTA from "@/components/_Reviver/atoms/CTA";

import "./OrderStep.css";
import { Condition, CreateOrderDropdownValue, DropdownOption, CompanySpecificProduct, Type } from "@/types";

interface Props {
  onChange: (change: any) => void;
  values: any;
  fieldValidationErrors: any;
  setFieldValidationErrors: Dispatch<SetStateAction<{}>>;
  companySpecificProduct: any;
  setCompanySpecificProduct: Dispatch<SetStateAction<[]>>;
  className?: string;
}

export default function OrderDetails(props: Props) {
  const { t } = useTranslation();

  const { onChange, values, fieldValidationErrors, companySpecificProduct, setCompanySpecificProduct } = props;

  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const [salesItemOptions, setSalesItemsOptions] = useState<DropdownOption[]>([]);
  const [selectedSalesItem, setSelectedSalesItem] = useState<DropdownOption | null>(null);
  const [sizeOptions, setSizeOptions] = useState<DropdownOption[]>([]);
  const [selectedSize, setSelectedSize] = useState<DropdownOption | null>(null);
  const [conditionOptions, setConditionOptions] = useState<DropdownOption[]>([]);
  const [selectedCondition, setSelectedCondition] = useState<any>(null);
  const [colorOptions, setcolorOptions] = useState<DropdownOption[]>([]);
  const [selectedColor, setSelectedColor] = useState<DropdownOption | null>(null);
  const [formatOptions, setFormatOptions] = useState<DropdownOption[]>([]);
  const [selectedFormat, setSelectedFormat] = useState<DropdownOption | null>(null);
  const [typeOptions, setTypeOptions] = useState<DropdownOption[]>([]);
  const [selectedType, setSelectedType] = useState<DropdownOption | null>(null);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [companySpecificOrdersOptions, setCompanySpecificOrdersOptions] = useState([]);
  const [amountDeviationOptions] = useState(amountDeviationOptionValues);
  const [selectedAmountDeviation, setSelectedAmountDeviation] = useState<DropdownOption | null>(null);
  const [deviationText, setDeviationText] = useState("");
  const [isCompanySpecificProductSet, setIsCompanySpecificProductSet] = useState(false);

  const setDefaultCurrency = useCallback(
    async (currencyOptions: any) => {
      async function asyncGetCurrentCompany() {
        return await getCompanyById(currentCompany.companyId);
      }
      let company = await asyncGetCurrentCompany();

      //Default currency
      const _defaultCurrency = currencyOptions.find((x: any) => x.label === company.data.defaultCurrency);
      setSelectedCurrency(_defaultCurrency);
      onChange({
        orderCurrency: {
          id: _defaultCurrency.value,
          value: _defaultCurrency.label,
        },
      });
    },
    [currentCompany.companyId, onChange]
  );

  useEffect(() => {
    let isMounted = true;

    async function loadOptions() {
      async function asyncGetDropdownData() {
        return await getCreateOrderValues();
      }
      async function asyncGetCompanySpecificProducts() {
        const companySpecificOrders = await getCompanySpecificProducts(currentCompany.companyId);
        return companySpecificOrders.data;
      }

      let dropDownValues = await asyncGetDropdownData();
      let companySpecificProductsResults = await asyncGetCompanySpecificProducts();

      //Sales item options
      const _salesItemOptions = dropDownValues.data.salesItems.map((salesItem: CreateOrderDropdownValue) => ({
        value: salesItem.id,
        label: t("common.plastic." + salesItem.value),
      }));
      const _sortedSalesItemOptions = SortDropDownOptions(_salesItemOptions);
      setSalesItemsOptions(_sortedSalesItemOptions);

      //Color options
      const _colorOptions = dropDownValues.data.colors.map((color: CreateOrderDropdownValue) => ({
        value: color.id,
        label: t("common.colors." + color.value),
      }));
      const _sortedcolorOptions = SortDropDownOptions(_colorOptions);
      setcolorOptions(_sortedcolorOptions);

      //Currency options
      const _currencyOptions = dropDownValues.data.currencies.map((currency: CreateOrderDropdownValue) => ({
        value: currency.id,
        label: currency.value.toUpperCase(),
      }));
      const _sortedCurrencyOptions = SortDropDownOptions(_currencyOptions);
      setCurrencyOptions(_sortedCurrencyOptions);

      //Format options
      const _formatOptions = dropDownValues.data.formats.map((format: CreateOrderDropdownValue) => ({
        value: format.id,
        label: t("common.formats." + format.value),
      }));
      const _sortedFormatOptions = SortDropDownOptions(_formatOptions);
      setFormatOptions(_sortedFormatOptions);

      //Size options
      const _sizeOptions = dropDownValues.data.sizes.map((size: CreateOrderDropdownValue) => ({
        value: size.id,
        label: size.value,
      }));
      const _sortedSizeOptions = SortDropDownOptions(_sizeOptions);
      setSizeOptions(_sortedSizeOptions);

      //CompanySpecificProduct options
      const _companySpecificProducts = companySpecificProductsResults.map((product: CompanySpecificProduct) => ({
        value: product.id,
        label: product.companySpecificProductName,
        product: product,
      }));
      const _sortedCompanySpecificProducts = SortDropDownOptions(_companySpecificProducts);
      setCompanySpecificOrdersOptions(_sortedCompanySpecificProducts);

      if (!selectedCurrency) await setDefaultCurrency(_currencyOptions);
    }

    if (isMounted) loadOptions();

    return () => {
      isMounted = false;
    };
  }, [t, currentCompany.companyId, setDefaultCurrency, selectedCurrency]);

  const asyncGetValidConditions = useCallback(
    async (salesItemId: number) => {
      const validConditions = await getValidConditions(salesItemId);
      const _conditionOptions: DropdownOption[] = validConditions.data.map((condition: Condition) => ({
        value: condition.id,
        label: t("common.conditions." + condition.condition),
      }));
      const _sortedConditionOptions = SortDropDownOptions(_conditionOptions);
      setConditionOptions(_sortedConditionOptions);

      return _conditionOptions;
    },
    [t]
  );

  const asyncGetValidTypes = useCallback(async (salesItemId: number, conditionId: number) => {
    const validTypes = await getValidTypes(salesItemId, conditionId);
    const _typeOptions: DropdownOption[] = validTypes.data.map((type: Type) => ({
      value: type.id,
      label: t("common.types." + type.type),
    }));
    const _sortedTypeOptions = SortDropDownOptions(_typeOptions);
    setTypeOptions(_sortedTypeOptions);

    return _typeOptions;
  }, []);

  //Set selected salesitem (used for going back)
  useEffect(() => {
    setSelectedSalesItem(salesItemOptions.find((item) => item.value === values.salesItem.id) || null);
    asyncGetValidConditions(values.salesItem.id);
  }, [salesItemOptions, asyncGetValidConditions, values.salesItem.id]);

  //Set selected condition (used for going back)
  useEffect(() => {
    setSelectedCondition(conditionOptions.find((condition) => condition.value === values.condition.id));
    asyncGetValidTypes(values.salesItem.id, values.condition.id);
  }, [conditionOptions, asyncGetValidTypes, values.salesItem.id, values.condition.id]);

  //Set selected size (used for going back)
  useEffect(() => {
    if (values.size) setSelectedSize(sizeOptions.find((size) => size.value === values.size.id) || null);
  }, [sizeOptions, values.size]);

  //Set selected format (used for going back)
  useEffect(() => {
    if (values.format) setSelectedFormat(formatOptions.find((x) => x.value === values.format.id) || null);
  }, [formatOptions, values.format]);

  //Set selected Type (used for going back)
  useEffect(() => {
    if (values.type) setSelectedType(typeOptions.find((x) => x.value === values.type.id) || null);
  }, [typeOptions, values.type]);

  //Set selected Color (used for going back)
  useEffect(() => {
    if (values.color) setSelectedColor(colorOptions.find((x) => x.value === values.color.id) || null);
  }, [colorOptions, values.color]);

  //Set selected amountDeviation (used for going back)
  useEffect(() => {
    if (values.amountDeviation > 0)
      setSelectedAmountDeviation(amountDeviationOptions.find((x) => x.value === values.amountDeviation) || null);
  }, [amountDeviationOptions, values.amountDeviation]);

  function resetConditionAndType() {
    setSelectedCondition(null);
    setConditionOptions([]);

    onChange({
      condition: {
        id: 0,
        value: "",
      },
    });

    resetType();
  }

  function resetType() {
    setSelectedType(null);
    setTypeOptions([]);

    onChange({
      type: null,
    });
  }

  function resetValues() {
    setSelectedSalesItem(null);

    setSelectedCondition(null);
    setConditionOptions([]);

    setSelectedType(null);
    setTypeOptions([]);

    setSelectedSize(null);
    setSelectedColor(null);
    setSelectedFormat(null);

    onChange({
      salesItem: {
        id: 0,
        value: "",
      },
      condition: {
        id: 0,
        value: "",
      },
      type: {
        id: 0,
        value: "",
      },
      size: null,
      color: null,
      format: null,
      packaging: {
        boxtype: 0,
        numberOfBoxes: 0,
        amountInEachBox: 0,
        palletPlaces: null,
      },
      companiesToTradeWith: [],
    });
  }

  async function onChangeCompanySpecificProduct(e: any) {
    setCompanySpecificProduct(e);
    setIsCompanySpecificProductSet(true);
    // Initialize create order object with companySpecificProduct values (also initialize dropdowns)
    const _salesItem = salesItemOptions.find((salesItem) => salesItem.value === e.product.salesItemId) || null;
    if (_salesItem) {
      const _conditionOptions = await onChangeSalesItem(_salesItem);
      const _condition = _conditionOptions.find((condition) => condition.value === e.product.conditionId);
      const _typeOptions = await onChangeCondition(_condition, _salesItem);

      const _type = _typeOptions.find((type) => type.value === e.product.typeId) || null;
      onChangeType(_type);
    }

    if (e.product.colorId > 0) {
      const _color = colorOptions.find((color) => color.value === e.product.colorId);
      onChangeColor(_color);
    } else {
      onChangeColor(null);
    }

    if (e.product.formatId > 0) {
      const _format = formatOptions.find((format) => format.value === e.product.formatId);
      onChangeFormat(_format);
    } else {
      onChangeFormat(null);
    }

    if (e.product.sizeId > 0) {
      const _size = sizeOptions.find((size) => size.value === e.product.sizeId);
      onChangeSize(_size);
    } else {
      onChangeSize(null);
    }

    // Also initiaze object for next steps in wizard..
    // onChange({
    //   companiesToTradeWith: e.product.orderTemplateNetwork.map((network: any) => network.companyId),
    // });
  }

  async function onChangeSalesItem(e: any) {
    setSelectedSalesItem(e);

    // Condition and Type depends on Material type (so reset these)
    resetConditionAndType();
    const conditionOptions = await asyncGetValidConditions(e.value);
    onChange({
      salesItem: {
        id: e.value,
        value: e.label,
      },
    });
    return conditionOptions;
  }

  function onChangeType(e: any) {
    setSelectedType(e);

    if (e === null) {
      onChange({ type: null });
    } else {
      onChange({
        type: {
          id: e.value,
          value: e.label,
        },
      });
    }
  }

  function onChangeSize(e: any) {
    setSelectedSize(e);

    onChange({
      size: {
        id: e.value,
        value: e.label,
      },
    });
  }

  async function onChangeCondition(e: any, selectedSalesItem: DropdownOption) {
    setSelectedCondition(e);

    // Type depends on Condition type (so reset this)
    resetType();

    const typeOptions = await asyncGetValidTypes(selectedSalesItem.value, e.value);
    onChange({
      condition: {
        id: e.value,
        value: e.label,
      },
    });
    return typeOptions;
  }

  function onChangeAmountDeviation(e: any) {
    setSelectedAmountDeviation(e);

    if (e === null) {
      calculateDeviation(values.amount, 0);
      onChange({ amountDeviation: 0 });
      return;
    }

    onChange({ amountDeviation: e.value });
    calculateDeviation(values.amount, e.value);
  }

  function onChangeAmount(e: any) {
    onChange({ amount: e });
    calculateDeviation(e, values.amountDeviation);
  }

  function calculateDeviation(amount: any, deviation: number) {
    if (amount <= 0 || deviation <= 0) {
      setDeviationText("");
      return;
    }

    const _deviation = (amount / 100) * deviation;
    const lowEnd = Number.parseFloat(amount) - _deviation;
    const highEnd = Number.parseFloat(amount) + _deviation;
    setDeviationText(`${lowEnd.toFixed(2)}kg - ${highEnd.toFixed(2)}kg`);
  }

  function onChangeColor(e: any) {
    setSelectedColor(e);

    if (e === null) {
      onChange({ color: null });
    } else {
      onChange({
        color: {
          id: e.value,
          value: e.label,
        },
      });
    }
  }

  function onChangeFormat(e: any) {
    setSelectedFormat(e);

    if (e === null) {
      onChange({ format: null });
      return;
    }
    onChange({
      format: {
        id: e.value,
        value: e.label,
      },
    });
  }

  function onChangeCurrency(e: any) {
    setSelectedCurrency(e);
    onChange({
      orderCurrency: {
        id: e.value,
        value: e.label,
      },
    });
  }

  function clearCompanySpecificProduct() {
    setIsCompanySpecificProductSet(false);
    setCompanySpecificProduct([]);
    setSelectedSalesItem({ value: 0, label: "" });
    resetValues();
  }

  return (
    <Grid className={`${props.className} create-order-container`}>
      <Grid.Row className="align">
        <Grid.Col span={6}>
          <Dropdown
            id="companySpecificProduct-options"
            label="Company Specific Product"
            options={companySpecificOrdersOptions}
            onChange={(e) => onChangeCompanySpecificProduct(e)}
            value={companySpecificProduct}
          />
          <CTA
            disabled={!isCompanySpecificProductSet}
            size="sm"
            id="clear-companySpecificProduct"
            onClick={clearCompanySpecificProduct}
            fullWidth
          >
            {t("commonOrders.clearCompanySpecificProduct")}
          </CTA>
        </Grid.Col>
        <Grid.Col span={6}>
          {/* <Checkbox
            id='create-order-promote'
            label={t('commonOrders.promoteOrder')}
            checked={values.promoted}
            onChange={(e) => onChange({ promoted: e.checked })}
          /> */}
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={6}>
          <TextfieldDropdown
            id="create-order-currencyprice"
            textFieldName="price"
            textfieldLabel={t("common.price") + " (per kg)"}
            textFieldType="number"
            textfieldValue={values.price}
            textfieldOnChange={(e) => onChange({ price: e.target.value })}
            dropdownLabel={t("common.currency")}
            dropdownOptions={currencyOptions}
            dropdownValue={selectedCurrency}
            dropdownOnChange={(e) => onChangeCurrency(e)}
            errormessage={fieldValidationErrors.price}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={6}>
          <TextField
            id="create-order-maxPrice"
            type="number"
            label={t("commonOrders.maxPrice") + " (per kg)"}
            value={values.maxPrice}
            onChange={(e: any) => onChange({ maxPrice: e.target.value })}
            disabled={values.priceAdjustPercentage}
            errormessage={fieldValidationErrors.maxPrice}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextField
            id="create-order-maxPrice"
            type="number"
            label={t("commonOrders.maxPricePercent") + " (per kg)"}
            value={values.priceAdjustPercentage}
            onChange={(e: any) => onChange({ priceAdjustPercentage: e.target.value })}
            disabled={values.maxPrice}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={6}>
          <TextfieldDropdown
            id="create-order-amount"
            textFieldName="amount"
            textfieldLabel={t("common.amount") + " (kg)"}
            textFieldType="number"
            textfieldOnChange={(e) => onChangeAmount(e.target.value)}
            textfieldValue={values.amount}
            dropdownLabel={"+/-"}
            dropdownOptions={amountDeviationOptions}
            dropdownValue={selectedAmountDeviation}
            dropdownOnChange={(e) => onChangeAmountDeviation(e)}
            dropdownIsClearable
            dropdownClearValue={() => onChangeAmountDeviation(null)}
            // menuPlacement={"top"}
            errormessage={fieldValidationErrors.amount}
          />
        </Grid.Col>
        <Grid.Col span={6} className="vbottom font-family-sans">
          {deviationText && (
            <>
              <p>{t("commonOrders.amountDeviationPre")}</p>
              <p>{deviationText}</p>
            </>
          )}
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-material"
            label={t("common.material")}
            name="salesItem"
            options={salesItemOptions}
            value={selectedSalesItem}
            onChange={(e) => onChangeSalesItem(e)}
            errormessage={fieldValidationErrors.salesItem}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-condition"
            label={t("common.condition")}
            menuPlacement="top"
            name="condition"
            options={conditionOptions}
            value={selectedCondition}
            onChange={(e) => onChangeCondition(e, selectedSalesItem!)}
            errormessage={fieldValidationErrors.condition}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-type"
            label={t("common.type")}
            menuPlacement="top"
            name="type"
            options={typeOptions}
            value={selectedType}
            onChange={(e) => onChangeType(e)}
            errormessage={fieldValidationErrors.type}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-format"
            label={t("common.format")}
            options={formatOptions}
            value={selectedFormat}
            menuPlacement="top"
            onChange={(e) => onChangeFormat(e)}
            isClearable
            clearValue={() => onChangeFormat(null)}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-size"
            name="size"
            label={t("common.size")}
            menuPlacement="top"
            options={sizeOptions}
            value={selectedSize}
            onChange={(e) => onChangeSize(e)}
            errormessage={fieldValidationErrors.size}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-color"
            label={t("common.color")}
            options={colorOptions}
            value={selectedColor}
            menuPlacement="top"
            onChange={(e) => onChangeColor(e)}
            isClearable
            clearValue={() => onChangeColor(null)}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
