import { FC, ReactNode } from "react";
import StatusTable from ".";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import CTA from "../../atoms/CTA";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import Tag from "../../atoms/Tag";
import Rating from "../../atoms/Rating";

interface Props {
  feedbackData: any[];
  openDrawerHandler: (item: any) => void;
}

const AdminFeedbackTable: FC<Props> = ({ feedbackData, openDrawerHandler }) => {
  const { t } = useTranslation();

  const tableHeaders: string[] = [
    "",
    t("commonAdmin.postedAt"),
    t("commonAdmin.comment"),
    t("commonAdmin.rating"),
    t("commonAdmin.status"),
    t("commonAdmin.receiverCompanyId"),
    t("commonAdmin.senderCompanyId"),
    t("commonAdmin.process"),
  ];

  const tableRows: ReactNode[][] = feedbackData.map((item) => [
    <SVGAsset name="yellow-exclamation" />,
    <Moment format="MMMM Do YYYY, HH:mm">{item.postedAt}</Moment>,
    item.comment,
    <Rating rating={item.rating.substr(1, 1)} />,
    <Tag status="REQUIRES_ACTION">{t("common.status.AWAITING_ACTION")}</Tag>,
    item.receiverCompanyId,
    item.senderCompanyId,
    <CTA onClick={() => openDrawerHandler(item)} size="sm" intent="secondary">
      {t("commonAdmin.process")}
    </CTA>,
  ]);

  return (
    <StatusTable
      tableHeaders={tableHeaders}
      tableRows={tableRows}
      statusIndex={4}
      accordionTriggerContentIndices={[0, 1]}
      accordionBorderStyle="warn"
      lastChildIsCta={true}
    />
  );
};

export default AdminFeedbackTable;
