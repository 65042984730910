import React, { useContext, useState, useEffect, ReactNode } from "react";
import Grid from "@/components/Grid/Grid";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import UserContext from "@/contexts/UserContext";
import Pagination from "@/components/Pagination/Pagination";
import { ErrorDialogContext } from "@/contexts/ErrorDialogContext";
import { ContractsHeader } from "@/components/_Reviver/molecules/SubHeader";

import styles from "./styles.module.css";
import CreateContractCta from "../../molecules/CreateContractCta";
import ContractCard from "../../organisms/ContractCard";
import CreateContract from "../../organisms/CreateContract";
import { getContracts } from "@/services/Contracts";
import { Contract } from "@/types";
import EditContract from "../../organisms/EditContract";
import ContractsTable from "../../organisms/StatusTable/ContractsTable";
import Heading from "../../atoms/Heading";

interface Props {
  setUserContext: any;
}

export default function Contracts(props: Props) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const errorDialogContext = useContext(ErrorDialogContext);
  const { setErrorDialogOptions = () => {}, showErrorDialog = () => {} } = errorDialogContext || {};
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [editContract, setEditContract] = useState<Contract | undefined>(undefined);

  const [contractWizardIsOpen, setContractWizardIsOpen] = useState(false);

  async function fetchContracts() {
    try {
      const response = await getContracts(currentCompany.companyId);
      setContracts(response.data);
    } catch (error) {
      setErrorDialogOptions({ message: "Fetching contracts failed", error: error as Error });
      showErrorDialog(true);
    }
  }

  useEffect(() => {
    setContracts([]);
    fetchContracts();
  }, [currentCompany]);

  return (
    <>
      <Helmet>
        <title>Reviver - {t("contracts.contracts")}</title>
        <meta name="description" content="Reviver - {t('contracts.contracts')}" />
      </Helmet>

      <CreateContract isOpen={contractWizardIsOpen} close={() => setContractWizardIsOpen(false)} />

      <div className={styles.contractsRoot}>
        {editContract ? (
          <>
            <EditContract contract={editContract} backToContractsList={() => setEditContract(undefined)} />
            <div className={styles.contractsTable}>
              <header className={styles.contractsTableHeader}>
                <Heading order={5} strong={true}>
                  4 tilhørende ordrer
                </Heading>
              </header>
              <ContractsTable />
            </div>
          </>
        ) : (
          <>
            <ContractsHeader
              companyName={currentCompany.companyName}
              totalContracts={contracts.length}
              sortOptions={[]}
            />
            <div className={styles.activeContracts}>
              <div className={styles.activeContractsInner}>
                <CreateContractCta onClick={() => setContractWizardIsOpen(true)} />
                {contracts.map((contract, i) => (
                  <ContractCard contract={contract} showDetails={() => setEditContract(contract)} key={i} />
                ))}
              </div>
            </div>
          </>
        )}
        <Grid>
          <Grid.Row>
            <Grid.Col span={12} className="flex right result-piginator">
              <Pagination numberOfPages={0} neighbours={2} onClick={() => {}} reset={!!currentCompany} />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
}
