import React, { useState, useRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import Grid from "@/components/Grid/Grid";
import Checkbox from "@/components/Checkbox/Checkbox";
import Dropdown from "@/components/Dropdown/Dropdown";
import TextField from "@/components/TextField/TextField";
import { ValidationErrors } from "@/types";
import CTA from "@/components/_Reviver/atoms/CTA";

interface Props {
  onValidationSuccess: (data: any) => void;
  onChangeValues: (changed: any) => void;
  values: any;
}

const Transport = forwardRef<HTMLFormElement, Props>(({ onValidationSuccess, onChangeValues, values }, ref) => {
  const { t } = useTranslation();
  const differentAddressNodeRef = useRef(null);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [differentAddressIsChecked, toggleDifferentAddressIsChecked] = useState<boolean>(false);
  const [trackingIsChecked, toggleTrackingIsChecked] = useState<boolean>(false);

  const onSubmit = (data: any) => {
    onValidationSuccess(data);
  };

  return (
    <>
      <form ref={ref} onSubmit={onSubmit}>
        <Grid compact={true}>
          <Grid.Row>
            <Grid.Col span={12}>
              <Checkbox
                disabled={true}
                label={t("commonOrders.differentAddress")}
                checked={differentAddressIsChecked}
                onChange={() => toggleDifferentAddressIsChecked(!differentAddressIsChecked)}
              />
            </Grid.Col>
          </Grid.Row>

          <CSSTransition
            in={differentAddressIsChecked}
            timeout={{
              enter: 1000,
              exit: 200,
            }}
            mountOnEnter
            unmountOnExit
            classNames="create-order-transitions"
            nodeRef={differentAddressNodeRef}
          >
            <Grid.Row>
              <Grid.Col span={12}>
                <div ref={differentAddressNodeRef}>
                  <Grid.Row className="mbottom20">
                    <Grid.Col span={6}>
                      <TextField
                        id="create-order-address"
                        name="address"
                        label={t("commonOrders.orderAddress")}
                        // value={values.orderAddress.address}
                        // onChange={(e) =>
                        //   onChange({
                        //     orderAddress: {
                        //       ...values.orderAddress,
                        //       address: e.target.value,
                        //     },
                        //   })
                        // }
                        // errormessage={fieldValidationAddressErrors.address}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Dropdown
                        id="add-address-country"
                        label={t("common.country")}
                        options={[]}
                        // options={countryOptions}
                        // onChange={(e) => setSelectedCountryHandler(e)}
                        // value={selectedCountry}
                        // errormessage={fieldValidationAddressErrors.countryCodeIso3}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col span={6}>
                      <TextField
                        id="create-order-postCode"
                        name="postCode"
                        type="number"
                        label={t("commonOrders.postCode")}
                        // value={values.orderAddress.postCode}
                        // onChange={(e) =>
                        //   onChange({
                        //     orderAddress: {
                        //       ...values.orderAddress,
                        //       postCode: e.target.value,
                        //     },
                        //   })
                        // }
                        // errormessage={fieldValidationAddressErrors.postCode}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextField
                        id="create-order-place"
                        name="place"
                        label={t("commonOrders.place")}
                        // value={values.orderAddress.place}
                        // onChange={(e) =>
                        //   onChange({
                        //     orderAddress: {
                        //       ...values.orderAddress,
                        //       place: e.target.value,
                        //     },
                        //   })
                        // }
                        // errormessage={fieldValidationAddressErrors.place}
                      />
                      <CTA
                        id="checkAddress"
                        // disabled={
                        //   !values.orderAddress.postCode ||
                        //   !values.orderAddress.address ||
                        //   !values.orderAddress.countryCodeIso3 ||
                        //   !values.orderAddress.place
                        // }
                        // onClick={() => validateDifferentAddress()}
                        onClick={() => {}}
                      >
                        {t("commonOrders.checkDelivery")}
                      </CTA>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mtop20 align">
                    {/* <Grid.Col span={12}>
                  {differentAddressValidationMessage.message && (
                    <MessageBar
                      type={differentAddressValidationMessage.messageType}
                      noIcon
                      message={differentAddressValidationMessage.message}
                    />
                  )}
                </Grid.Col> */}
                  </Grid.Row>
                </div>
              </Grid.Col>
            </Grid.Row>
          </CSSTransition>
          <Grid.Row>
            <Grid.Col span={12}>
              <Checkbox
                disabled={true}
                label="Legge til sporing til alle leveranser i denne kontrakten
(NB: tilleggsgebyrer påløper)"
                checked={trackingIsChecked}
                onChange={() => toggleTrackingIsChecked(!trackingIsChecked)}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </form>
    </>
  );
});

export default Transport;
