import moment from "moment-timezone";
import { stripTime } from "../utils/Dates";

export default function getCreateNewOrderObject() {
  let createNewOrderObject = {
    companyId: 0,
    userId: 0,
    salesItem: {
      id: 0,
      value: "",
    },
    amount: "",
    minimumAmount: "",
    unit: {
      id: 1,
      unit: "kg",
    },
    validTo: stripTime(moment().add(2, "d").toDate()),
    validFrom: stripTime(moment().toDate()),
    deliveryStart: null,
    deliveryEnd: null,
    price: "",
    priceUnit: {
      id: 1,
      unit: "kg",
    },
    priceAdjustPercentage: "",
    maxPrice: "",
    minPrice: "",
    orderCurrency: {
      id: 0,
      value: "string",
    },
    format: null,
    type: null,
    color: null,
    orderType: 0,
    size: null,
    description: "",
    images: [],
    condition: {
      id: 0,
      value: "",
    },
    catchDate: null,
    expirationDate: null,
    packingDate: null,
    msc: true,
    transportFrom: "",
    promoted: false,
    autoSalesOrder: false,
    packaging: {
      boxtype: 0,
      numberOfBoxes: 0,
      amountInEachBox: 0,
      palletPlaces: null,
    },
    lastDeliveryDate: null,
    orderAddress: {
      address: "",
      postCode: "",
      place: "",
      countryCodeIso3: "",
      countryCodeIso2: "",
      isEu: false,
    },
    amountDeviation: 0,
  };

  return createNewOrderObject;
}
