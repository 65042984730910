import Joi from "joi";

export function companySettingsSchema(translation) {
  const t = translation;

  return Joi.object({
    companyName: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.companyNameRequired"),
        "any.required": t("validation.companyNameRequired"),
        "string.max": t("validation.companyNameMaxLength"),
      }),
    email: Joi.string()
      .max(100)
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": t("validation.emailRequired"),
        "string.email": t("validation.emailFormatError"),
        "any.required": t("validation.emailRequired"),
        "string.max": t("validation.emailMaxLength"),
      }),
    invoiceemail: Joi.string()
      .max(100)
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": t("validation.emailRequired"),
        "string.email": t("validation.emailFormatError"),
        "any.required": t("validation.emailRequired"),
        "string.max": t("validation.emailMaxLength"),
      }),
    tradingemail: Joi.string()
      .max(100)
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": t("validation.emailRequired"),
        "string.email": t("validation.emailFormatError"),
        "any.required": t("validation.emailRequired"),
        "string.max": t("validation.emailMaxLength"),
      }),
    vatNumber: Joi.string()
      .alphanum()
      .min(8)
      .max(12)
      .required()
      .messages({
        "string.base": t("validation.vatNumberRequired"),
        "string.empty": t("validation.vatNumberRequired"),
        "any.required": t("validation.vatNumberRequired"),
        "string.min": t("validation.vatNumberLength"),
        "string.max": t("validation.vatNumberLength"),
        "string.alphanum": t("validation.vatNumberAlphanumeric"),
      }),
    leiNumber: Joi.string()
      .allow(null, "")
      .alphanum()
      .min(20)
      .max(20)
      .messages({
        "string.min": t("validation.leiNumberLength"),
        "string.max": t("validation.leiNumberLength"),
        "string.alphanum": t("validation.leiNumberAlphanumeric"),
      }),
    phone: Joi.number()
      .min(99999)
      .unsafe()
      .max(999999999999999999999999999999)
      .required()
      .messages({
        "number.base": t("validation.phoneNumberRequired"),
        "any.required": t("validation.phoneNumberRequired"),
        "number.max": t("validation.phoneNumberMaxLength"),
        "number.min": t("validation.phoneNumberRequired"),
        "number.unsafe": t("validation.phoneNumberMaxLength"),
      }),
    companyType: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.companyTypeRequired"),
        "any.required": t("validation.companyTypeRequired"),
        "string.max": t("validation.companyTypeMaxLength"),
      }),
    exportLicense: Joi.string()
      .max(100)
      .allow(null, "")
      .messages({
        "string.max": t("validation.exportLicenseMaxLength"),
      }),
    language: Joi.alternatives(
      Joi.object({
        id: Joi.number(),
        name: Joi.string(),
        languageCode: Joi.string(),
      })
    )
      .required()
      .messages({
        "alternatives.match": t("validation.languageRequired"),
      }),
    timeZone: Joi.string()
      .required()
      .messages({
        "string.empty": t("validation.timeZoneRequired"),
        "any.required": t("validation.timeZoneRequired"),
      }),
    currency: Joi.alternatives(
      Joi.object({
        label: Joi.string(),
      })
    ).messages({ "alternatives.match": t("validation.currencyRequired") }),
  }).unknown();
}

export function addressSettingsSchema(translation) {
  const t = translation;
  return Joi.object({
    address: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.addressRequired"),
        "any.required": t("validation.addressRequired"),
        "string.max": t("validation.addressMaxLength"),
      }),
    postCode: Joi.number()
      .unsafe()
      .max(9999999999)
      .required()
      .messages({
        "number.base": t("validation.postCodeRequired"),
        "any.required": t("validation.postCodeRequired"),
        "number.max": t("validation.postCodeMaxLength"),
        "number.unsafe": t("validation.postCodeMaxLength"),
      }),
    place: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.placeRequired"),
        "any.required": t("validation.placeRequired"),
        "string.max": t("validation.placeMaxLength"),
      }),
    country: Joi.string()
      .required()
      .messages({
        "number.base": t("validation.countryRequired"),
        "any.required": t("validation.countryRequired"),
      }),
    addressType: Joi.string()
      .required()
      .messages({
        "string.empty": t("validation.addressTypeRequired"),
        "any.required": t("validation.addressTypeRequired"),
      }),
  }).unknown();
}

export function bankAccountSettingsSchema(translation) {
  const t = translation;

  return Joi.object({
    name: Joi.string()
      .max(255)
      .required()
      .messages({
        "string.empty": t("validation.bankAccountNameRequired"),
        "any.required": t("validation.bankAccountNameRequired"),
        "string.max": t("validation.bankAccountNameMaxLength"),
      }),
    iban: Joi.string()
      .max(255)
      .required()
      .messages({
        "string.empty": t("validation.ibanRequired"),
        "any.required": t("validation.ibanRequired"),
        "string.max": t("validation.ibanMaxLength"),
      }),
    accountNo: Joi.number()
      .unsafe()
      .allow(1, 2, 3, 4, 5, 6, 7, 8, 9, 0)
      .integer()
      .positive()
      .max(99999999999999999999)
      .required()
      .messages({
        "number.base": t("validation.bankAccountNumbersOnly"),
        "any.required": t("validation.bankAccountNumberRequired"),
        "number.max": t("validation.bankAccountNumberMaxLength"),
        "number.integer": t("validation.bankAccountNumbersOnly"),
        "number.positive": t("validation.bankAccountNumbersOnly"),
        "number.unsafe": t("validation.bankAccountNumberMaxLength"),
      }),
  }).unknown();
}
