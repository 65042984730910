import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { approveFeedback, declineFeedback, getAllFeedback } from "../../services/Feedback";
import { ErrorDialogContext } from "../../contexts/ErrorDialogContext";
import { Helmet } from "react-helmet-async";

import Spinner from "../../components/Spinner/Spinner";
import Drawer from "../../components/Drawer/Drawer";
import Grid from "../../components/Grid/Grid";
import Moment from "react-moment";

import CTA from "@/components/_Reviver/atoms/CTA";
import AdminFeedbackTable from "@/components/_Reviver/organisms/StatusTable/AdminFeedbackTable";

import styles from "./styles.module.css";
import Heading from "@/components/_Reviver/atoms/Heading";

export default function AdminFeedback() {
  const { t } = useTranslation();
  const { showErrorDialog, setErrorDialogOptions } = useContext(ErrorDialogContext);

  const [allFeedbackData, setAllFeedbackData] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsLoadingDrawer] = useState(false);
  const [feedbackInDrawer, setFeedbackInDrawer] = useState({});

  useEffect(() => {
    async function asyncgetAllFeedback() {
      setIsLoading(true);
      try {
        const response = await getAllFeedback();
        setAllFeedbackData(response.data);
        setIsLoading(false);
      } catch (error) {
        setErrorDialogOptions({
          message: t("commonAdmin.retrievingFailed"),
          error,
        });
        showErrorDialog(true);
      }
    }
    asyncgetAllFeedback();
  }, [setErrorDialogOptions, showErrorDialog, t]);

  async function asyncApproveFeedback(feedbackId) {
    setIsLoading(true);
    try {
      const response = await approveFeedback(feedbackId);
      await refreshFeedback();
      console.log(response.data);
      setIsLoading(false);
    } catch (error) {
      setErrorDialogOptions({
        message: t("commonAdmin.approvalFailed"),
        error,
      });
      showErrorDialog(true);
    }
  }

  async function asyncDeclineFeedback(feedbackId) {
    setIsLoading(true);
    try {
      const response = await declineFeedback(feedbackId);
      await refreshFeedback();
      console.log(response.data);
      setIsLoading(false);
    } catch (error) {
      setErrorDialogOptions({
        message: t("commonAdmin.decliningFailed"),
        error,
      });
      showErrorDialog(true);
    }
  }

  async function refreshFeedback() {
    setIsLoading(true);
    try {
      const response = await getAllFeedback();
      setAllFeedbackData(response.data);
      setIsLoading(false);
    } catch (error) {
      setErrorDialogOptions({ message: t("commonAdmin.refreshFailed"), error });
      showErrorDialog(true);
    }
  }

  async function openDrawerHandler(item) {
    setIsDrawerOpen(true);
    setIsLoadingDrawer(true);
    setFeedbackInDrawer(item);
    setIsLoadingDrawer(false);
  }

  async function declineFeedbackAndCloseDrawer() {
    asyncDeclineFeedback(feedbackInDrawer.id);
    setIsDrawerOpen(false);
  }

  async function acceptFeedbackAndCloseDrawer() {
    asyncApproveFeedback(feedbackInDrawer.id);
    setIsDrawerOpen(false);
  }
  return (
    <div className={styles.adminFeedbackRoot}>
      <Helmet>
        <title>Admin - Reviver - {t("commonAdmin.adminFeedback")}</title>
        <meta name="description" content="Admin - Reviver - {t('commonAdmin.adminFeedback')}" />
      </Helmet>
      <header className={styles.adminFeedbackHeader}>
        <Heading order={3} strong={true}>
          {t("commonAdmin.feedback")}
        </Heading>
      </header>
      <Spinner loading={loading} inline={true} align="center" />
      {!!allFeedbackData && <AdminFeedbackTable feedbackData={allFeedbackData} openDrawerHandler={openDrawerHandler} />}
      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Spinner loading={isDrawerLoading} />
        {feedbackInDrawer && (
          <div className="full flex col">
            <div className="flex gap10 align pad20">
              <Grid className="font-family-sans pad30 taskinfo-container">
                <Grid.Row>
                  <Grid.Col span={4}>
                    <h3>{t("commonAdmin.feedback")}</h3>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <label>{t("commonAdmin.postedAt")}</label>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <span>{<Moment format="MMMM Do YYYY, HH:mm">{feedbackInDrawer.postedAt}</Moment>}</span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <label>{t("commonAdmin.comment")}</label>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <span>{feedbackInDrawer.comment}</span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <label>{t("commonAdmin.rating")}</label>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <span>{feedbackInDrawer.rating}</span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <label>{t("commonAdmin.receiverCompanyId")}</label>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <span>{feedbackInDrawer.receiverCompanyId}</span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <label>{t("commonAdmin.senderCompanyId")}</label>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <span>{feedbackInDrawer.senderCompanyId}</span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <label>
                      <CTA intent="secondary" onClick={() => declineFeedbackAndCloseDrawer()} size="sm">
                        {t("commonAdmin.decline")}
                      </CTA>
                    </label>
                  </Grid.Col>
                  <Grid.Col>
                    <label>
                      <CTA onClick={() => acceptFeedbackAndCloseDrawer()} size="sm">
                        {t("commonAdmin.approve")}
                      </CTA>
                    </label>
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}
