import { FC, PropsWithChildren, CSSProperties } from "react";
import styles from "./styles.module.css";

export interface StackProps {
  align?: CSSProperties["alignItems"];
  gap?: CSSProperties["gap"];
  justify?: CSSProperties["justifyContent"];
  className?: string;
  grow?: boolean;
}

const Stack: FC<PropsWithChildren<StackProps>> = ({ align, gap, justify, grow, className, ...props }) => {
  const style = {
    "--stack-align": align,
    "--stack-gap": gap,
    "--stack-justify": justify,
    "--stack-grow": grow ? 1 : "",
  } as React.CSSProperties;
  return (
    <div className={`${styles.stackRoot} ${className ? className : ""}`} style={style}>
      {props.children}
    </div>
  );
};

export default Stack;
