import { FC, PropsWithChildren } from "react";
import { Text as MantineText, TextProps as MantineTextProps } from "@mantine/core";
import styles from "./styles.module.css";

export type TextSize = "xl" | "lg" | "md" | "sm" | "xs" | "xxs";
export interface TextProps extends MantineTextProps {
  display?: "body" | "technical";
  strong?: boolean;
  span?: boolean;
  size?: TextSize;
  color?: "primary" | "secondary" | "tertiary" | "warning" | "error";
  nowrap?: boolean;
}

const Text: FC<PropsWithChildren<TextProps>> = ({
  display = "body",
  strong = false,
  size,
  color,
  nowrap,
  ...props
}) => {
  const fontWeightVar = `var(--text-weight-${display === "body" ? "sans" : "mono"}-${strong ? "strong" : "default"})`;
  return (
    <MantineText
      {...props}
      className={`${styles.textRoot} ${props.className || ""}`}
      vars={() => ({
        root: {
          ...(size ? { "--text-fz": `var(--text-typography-body-${size})` } : {}),
          "--font-weight": fontWeightVar,
          "--font-family": `var(--text-font-${display === "body" ? "sans" : "mono"})`,
          "--font-color": color ? `var(--color-text-${color})` : "inherit",
          ...(nowrap ? { "--font-whitespace": `nowrap` } : {}),
        },
      })}
    >
      {props.children}
    </MantineText>
  );
};

export default Text;
