import React, { useState, useEffect } from "react";
import StepLabel from "../_Reviver/atoms/StepLabel";

import "./TabsWiz.css";

function TabsWiz(props) {
  const [active, setActive] = useState(0);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const tabs = props.children.map((tab, index) => {
      if (tab.props.isActive) setActive(index);
      let status = "";
      if (tab.props.isActive) {
        status = "active";
      } else if (tab.props.isDone) {
        status = "done";
      } else if (tab.props.isActive === false) {
        status = "notActive";
      }
      return <StepLabel label={tab.props.stepTitle} titleStatus={status} />;
    });
    setTabs(tabs);
  }, [props.children]);

  return (
    <div className="tabswiz">
      <div className="menu">
        {tabs.map((tab, index) => {
          return (
            <div className={`item ${index !== 0 ? "hasBorder" : ""}`} key={index}>
              {tab}
            </div>
          );
        })}
      </div>
      <div className="tabswiz-content">{props.children[active]}</div>
    </div>
  );
}

export default TabsWiz;
