import Joi from "joi";

export function createStep1SchemaSales(translation) {
  const t = translation;

  return Joi.object({
    amount: Joi.number()
      .options({ convert: false })
      .precision(2)
      .max(1000000000)
      .positive()
      .required()
      .messages({
        "number.base": t("validation.amountRequired"),
        "number.empty": t("validation.amountRequired"),
        "number.positive": t("validation.amountMustBePositive"),
        "number.max": t("validation.maxAmount"),
        "number.precision": t("validation.amountPrecision"),
      }),
    minimumAmount: Joi.number().when("amount", {
      is: Joi.number().required(),
      then: Joi.number()
        .options({ convert: false })
        .max(Joi.ref("amount"))
        .precision(2)
        .allow(null, 0)
        .positive()
        .messages({
          "number.precision": t("validation.minimumAmountPrecision"),
          "number.positive": t("validation.minimumAmountMustBePositive"),
          "number.max": t("validation.amountGreaterThanMinimumAmount"),
        }),
    }),
    price: Joi.number()
      .unsafe()
      .positive()
      .required()
      .messages({
        "number.base": t("validation.priceRequired"),
        "number.empty": t("validation.priceRequired"),
        "number.positive": t("validation.priceRequired"),
        "number.unsafe": t("validation.priceRequired"),
      }),
    minPrice: Joi.number().when("price", {
      is: Joi.number().required(),
      then: Joi.number()
        .unsafe()
        .positive()
        .allow(null, 0)
        .max(Joi.ref("price"))
        .messages({
          "number.positive": t("validation.minPricePositive"),
          "number.max": t("validation.minPriceMax"),
        }),
    }),
    salesItem: Joi.alternatives(
      Joi.object({
        id: Joi.number(),
        value: Joi.string(),
      }).required(),
      Joi.object({
        value: Joi.number(),
        label: Joi.string(),
      }).required()
    ).messages({ "alternatives.match": t("validation.materialRequired") }),
    condition: Joi.alternatives(
      Joi.object({
        id: Joi.number(),
        value: Joi.string(),
      }),
      Joi.object({
        value: Joi.number(),
        label: Joi.string(),
      })
    ).messages({ "alternatives.match": t("validation.conditionRequired") }),
    size: Joi.alternatives(
      Joi.object({
        id: Joi.number(),
        value: Joi.string(),
      }),
      Joi.object({
        value: Joi.number(),
        label: Joi.string(),
      })
    ).messages({ "alternatives.match": t("validation.sizeRequired") }),
  }).unknown();
}

export function createStep1SchemaBuy(translation) {
  const t = translation;

  return Joi.object({
    amount: Joi.number()
      .options({ convert: false })
      .precision(2)
      .max(1000000000)
      .positive()
      .required()
      .messages({
        "number.base": t("validation.amountRequired"),
        "number.empty": t("validation.amountRequired"),
        "number.positive": t("validation.amountMustBePositive"),
        "number.max": t("validation.maxAmount"),
        "number.precision": t("validation.amountPrecision"),
        "number.min": t("validation.amountGreaterThanMinimumAmount"),
      }),
    price: Joi.number()
      .unsafe()
      .positive()
      .required()
      .messages({
        "number.base": t("validation.priceRequired"),
        "number.empty": t("validation.priceRequired"),
        "number.positive": t("validation.priceRequired"),
        "number.unsafe": t("validation.priceRequired"),
      }),
    maxPrice: Joi.number().when("price", {
      is: Joi.number().required(),
      then: Joi.number()
        .unsafe()
        .positive()
        .allow(null, 0)
        .greater(Joi.ref("price"))
        .messages({
          "number.base": t("validation.maxPriceMinimum"),
          "number.positive": t("validation.maxPricePositive"),
          "number.greater": t("validation.maxPriceMinimum"),
          "number.ref": t("validation.maxPriceMinimum"),
        }),
    }),
    salesItem: Joi.alternatives(
      Joi.object({
        id: Joi.number(),
        value: Joi.string(),
      }).required(),
      Joi.object({
        value: Joi.number(),
        label: Joi.string(),
      }).required()
    ).messages({ "alternatives.match": t("validation.materialRequired") }),
    condition: Joi.alternatives(
      Joi.object({
        id: Joi.number(),
        value: Joi.string(),
      }),
      Joi.object({
        value: Joi.number(),
        label: Joi.string(),
      })
    ).messages({ "alternatives.match": t("validation.conditionRequired") }),
    size: Joi.alternatives(
      Joi.object({
        id: Joi.number(),
        value: Joi.string(),
      }),
      Joi.object({
        value: Joi.number(),
        label: Joi.string(),
      })
    ).messages({ "alternatives.match": t("validation.sizeRequired") }),
  }).unknown();
}

export function createStep2Schema(translation) {
  const t = translation;

  return Joi.object({
    validFrom: Joi.date()
      .required()
      .messages({
        "date.base": t("validation.validFromRequired"),
      }),
    validTo: Joi.date()
      .required()
      .messages({
        "date.base": t("validation.validToRequired"),
      }),
    description: Joi.string()
      .allow(null, "")
      .max(200)
      .messages({
        "string.max": t("validation.descriptionMaxLength"),
      }),
  }).unknown();
}

export function createStep2AddressSchema(translation) {
  const t = translation;

  return Joi.object({
    address: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.addressRequired"),
        "string.max": t("validation.addressMaxLength"),
      }),
    countryCodeIso3: Joi.string()
      .required()
      .messages({
        "string.base": t("validation.countryRequired"),
        "string.required": t("validation.countryRequired"),
        "string.empty": t("validation.countryRequired"),
      }),
    postCode: Joi.number()
      .unsafe()
      .max(9999999999)
      .required()
      .messages({
        "number.base": t("validation.postCodeRequired"),
        "any.required": t("validation.postCodeRequired"),
        "number.max": t("validation.postCodeMaxLength"),
        "number.unsafe": t("validation.postCodeMaxLength"),
      }),
    place: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.placeRequired"),
        "string.max": t("validation.placeMaxLength"),
      }),
  });
}

export function createStep3Schema(translation) {
  const t = translation;

  return Joi.object({
    boxtype: Joi.number()
      .greater(0)
      .required()
      .messages({
        "any.required": t("validation.boxtypeRequired"),
        "number.greater": t("validation.boxtypeRequired"),
      }),
    numberOfBoxes: Joi.number()
      .greater(0)
      .integer()
      .required()
      .messages({
        "number.base": t("validation.numberOfBoxesRequired"),
        "number.greater": t("validation.numberOfBoxesRequired"),
        "number.integer": t("validation.numberOfBoxesInteger"),
      }),
  }).unknown();
}

export function createFileUploadSchema(translation) {
  const t = translation;

  return Joi.object({
    images: Joi.array()
      .min(1)
      .required()
      .messages({
        "array.min": t("validation.numberOfImagesRequired"),
        "any.required": t("validation.numberOfImagesRequired"),
      }),
  }).unknown();
}
