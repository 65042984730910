import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCompanyByIdPublic } from "../../../../services/Company";

import Moment from "react-moment";
import OrderTypeEnum from "../../../../constants/enums/OrderTypeEnum";

export default function TransactionTabContent(props) {
  const { t } = useTranslation();
  const { order } = props;
  const [companiesArray, setCompaniesArray] = useState();

  useEffect(() => {
    async function asyncLoadTransactionCompanies() {
      let companyIdArray = [];

      if (order) {
        if (order.orderType.id === OrderTypeEnum.SELL || order.orderType.id === OrderTypeEnum.AUCTION) {
          companyIdArray = order.transactions.map((x) => {
            return x.settlement.settlement.buyer.companyId;
          });
        }

        if (order.orderType.id === OrderTypeEnum.BUY) {
          companyIdArray = order.transactions.map((x) => {
            return x.settlement.settlement.seller.companyId;
          });
        }
      }

      let companies = [];
      for await (const id of companyIdArray) {
        const res = await getCompanyByIdPublic(id);
        companies.push(res.data);
      }

      setCompaniesArray(companies);
    }

    asyncLoadTransactionCompanies();
  }, [order]);

  return (
    <div>
      {/* Purchase order === show seller, Sell order/Auction === show buyer */}
      <table className="compact lines">
        <thead>
          <tr>
            <th scope="col">{t("commonOrders.transactionDate")}</th>
            <th scope="col">{t("dashboard.status")}</th>
            {order.orderType.id === OrderTypeEnum.BUY ? (
              <th scope="col">{t("commonOrders.seller")}</th>
            ) : (
              <th scope="col">{t("commonOrders.buyer")}</th>
            )}
            <th scope="col">{t("common.price")}</th>
            <th scope="col">{t("common.amount")}</th>
            <th scope="col">{t("commonOrders.transactionDone")}</th>
          </tr>
        </thead>

        <tbody>
          {order.transactions.map((trs, index) => (
            <tr key={`table-${index}`}>
              <td data-label={t("commonOrders.transactionDate")}>
                {trs.finalAmount === 0 && order.amountDeviation !== 0 ? (
                  "-"
                ) : (
                  <Moment format="DD.MM.YYYY">{trs.transactionDate}</Moment>
                )}
              </td>
              <td data-label={t("dashboard.status")}>
                {trs.finalAmount === 0 && order.amountDeviation !== 0 ? "-" : t("common.orderStatus." + trs.status)}
              </td>
              {(order.orderType.id === OrderTypeEnum.SELL || order.orderType.id === OrderTypeEnum.AUCTION) && (
                <td data-label={t("commonOrders.buyer")} className="break-word">
                  {trs.finalAmount === 0 && order.amountDeviation !== 0
                    ? "-"
                    : companiesArray &&
                      companiesArray.find((company) => company.id === trs.settlement.settlement.buyer.companyId)
                        .companyName}
                </td>
              )}
              {order.orderType.id === OrderTypeEnum.BUY && (
                <td data-label={t("commonOrders.seller")} className="break-word">
                  {trs.finalAmount === 0 && order.amountDeviation !== 0
                    ? "-"
                    : companiesArray &&
                      companiesArray.find((company) => company.id === trs.settlement.settlement.seller.companyId)
                        .companyName}
                </td>
              )}
              {(order.orderType.id === OrderTypeEnum.SELL || order.orderType.id === OrderTypeEnum.AUCTION) && (
                <td data-label={t("common.price")}>
                  {trs.finalAmount === 0 && order.amountDeviation !== 0
                    ? "-"
                    : trs.settlement.settlement.seller.totalPrice + " " + trs.settlement.settlement.seller.currency}
                </td>
              )}
              {order.orderType.id === OrderTypeEnum.BUY && (
                <td data-label={t("common.price")}>
                  {trs.finalAmount === 0 && order.amountDeviation !== 0
                    ? "-"
                    : trs.settlement.settlement.buyer.totalPrice + " " + trs.settlement.settlement.buyer.currency}
                </td>
              )}
              <td data-label={t("common.amount")}>
                {trs.finalAmount === 0 && order.amountDeviation !== 0
                  ? "-"
                  : order.amountDeviation > 0
                    ? trs.finalAmount > 0
                      ? trs.finalAmount + trs.unit
                      : "-"
                    : trs.amount > 0
                      ? trs.amount + trs.unit
                      : "-"}
              </td>
              <td data-label={t("commonOrders.transactionDone")}>
                {trs.finalAmount === 0 && order.amountDeviation !== 0 ? (
                  "-"
                ) : trs.transactionClosed !== null ? (
                  <Moment format="DD.MM.YYYY">{trs.transactionClosed}</Moment>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))}
          {order.transactions.length === 0 && (
            <tr>
              <td>{"-"}</td>
              <td>{"-"}</td>
              <td>{"-"}</td>
              <td>{"-"}</td>
              <td>{"-"}</td>
              <td>{"-"}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
