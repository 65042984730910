import { FC, PropsWithChildren } from "react";
import styles from "./styles.module.css";
import clsx from "clsx";

interface Props {
  margin?: boolean;
  visible?: boolean;
}

const Divider: FC<Props> = ({ margin = true, visible = true }) => {
  const classes = clsx(styles.dividerRoot, {
    [styles.margin]: margin,
    [styles.visible]: visible,
  });
  return <hr className={classes} />;
};

export default Divider;
