import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import "./Dropdown.css";

export const customDropdownStyles = {
  control: (base) => ({
    ...base,
    borderRadius: "2px",
    border: "var(--border-secondary-normal)",
    minHeight: "36px",

    ":hover": {
      border: "var(--border-primary-normal)",
    },

    ":focus-within": {
      border: "var(--border-primary-normal)",
      boxShadow: "none",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "var(--color-text-secondary)",
  }),
  indicatorSeparator: () => ({
    visibility: "hidden",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: "var(--radius)",
  }),
  singleValue: (base) => ({
    ...base,
    color: "var(--color-text-secondary)",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor:
      state.isFocused && state.isSelected
        ? "var(--color-background-contrast)"
        : state.isSelected
          ? "var(--color-background-contrast)"
          : state.isFocused
            ? "var(--color-background-secondary)"
            : undefined,
    color:
      state.isFocused && state.isSelected
        ? "var(--color-brand-aqua-100)"
        : state.isSelected
          ? "var(--color-white)"
          : state.isFocused
            ? "var(--color-text-primary)"
            : "var(--color-text-secondary)",
    "&:active": {
      backgroundColor: "var(--color-brand-aqua-75)",
    },
  }),
};

const menuPlacement = {
  Bottom: "bottom",
  Top: "top",
};
dropdown.menuPlacement = menuPlacement;

function dropdown(props) {
  // overrideStyle lets you override style from outside component if necessary
  const { overrideStyle, label, options, direction, placeholder, errormessage, dropDownClassNames, isDisabled } = props;

  const filterDropdown = (inputValue) => {
    const getLabel = (option) => option.label || option.value || "";
    return props.options.filter((i) => getLabel(i).toLowerCase().includes(inputValue.toLowerCase()));
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterDropdown(inputValue));
      }, 1000);
    });

  return (
    <div className={`dropdown-area ${dropDownClassNames} ${isDisabled ? "disabled" : ""}`}>
      <label>{label}</label>
      <AsyncSelect
        key={JSON.stringify(options)}
        styles={overrideStyle ? overrideStyle : customDropdownStyles}
        placeholder={placeholder ? placeholder : false}
        noOptionsMessage={() => "..."}
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        id={props.id ? props.id + "-dropdown" : null}
        menuPlacement={`${direction ? direction : dropdown.menuPlacement.Bottom}`}
        {...props}
      />
      <ErrorMessage errormessage={errormessage} />
    </div>
  );
}

dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  menuPlacement: PropTypes.oneOf(["top", "bottom"]),
  value: PropTypes.any,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
  errormessage: PropTypes.string,
  dropDownClassNames: PropTypes.string,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  clearValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
};

export default dropdown;
