import moment from "moment-timezone";
import { stripTime } from "../utils/Dates";
import ContractTypeEnum from "@/constants/enums/ContractTypeEnum";
import ContractTradeTypeEnum from "@/constants/enums/ContractTradeTypeEnum";

export default function getCreateNewContractObject() {
  let createNewOrderObject = {
    name: "",
    description: "",
    contractBuyers: [],
    contractSellers: [],
    contractDate: stripTime(moment().toDate()),
    contractStartDate: "",
    contractEndDate: "",
    companySpecificProductId: "",
    useTransportServices: false,
    amountOnContract: "",
    agreedPricePrKg: 0,
    contractPaymentId: 1,
    contractTradeType: ContractTradeTypeEnum.BUY,
    contractType: ContractTypeEnum.FIXED_AMOUNT
  };

  return createNewOrderObject;
}
