import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ActiveTeamMember, Company } from "@/types";
import SettingsTable from ".";

interface UsersTableProps {
  activeTeamMembers: ActiveTeamMember[];
  isAdmin: boolean;
  openEditTeamMemberDialog: (index: number, array: any[]) => void;
  removeTeamMember: (index: number, array: any[]) => void;
}

const UsersTable: FC<UsersTableProps> = ({
  activeTeamMembers,
  isAdmin,
  openEditTeamMemberDialog,
  removeTeamMember,
}) => {
  const { t } = useTranslation();
  function createEditActions(index: number) {
    return [
      {
        label: t("settings.edit"),
        action: () => openEditTeamMemberDialog(index, activeTeamMembers),
      },
      {
        label: t("settings.delete"),
        action: () => removeTeamMember(index, activeTeamMembers),
      },
    ];
  }
  const tableHeaders: string[] = [t("common.user"), t("common.email"), t("common.role")];
  const tableRows: ReactNode[][] = activeTeamMembers.map((activeUser) => [
    `${activeUser.firstName} ${activeUser.lastName}`,
    activeUser.email,
    activeUser.roleType,
  ]);

  return (
    <SettingsTable
      tableHeaders={tableHeaders}
      tableRows={tableRows}
      createEditActions={createEditActions}
      isAdmin={isAdmin}
      accordionTriggerContentIndex={0}
    />
  );
};

export default UsersTable;
