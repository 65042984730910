import { CreateContractDto } from "@/types";
import { axios, APIBASE_REVIVERCONTRACTS } from "./ApiConfig";

export const getContracts = async (companyId: any) => axios.get(`${APIBASE_REVIVERCONTRACTS}api/search/all`, {
  params: {
    companyId
  }
});

export const postCreateContract = async (contract: CreateContractDto, companyId: any) =>
  axios.post(`${APIBASE_REVIVERCONTRACTS}api/contracts/create`, contract, {
    params: {
      companyId
    }
  });