import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetValidConditions,
  useGetValidTypes,
  useGetBoxtypeValues,
  useGetCreateOrderValues,
} from "@/services/Orders";
import { useGetAllCompanies } from "@/services/Company";
import { Controller, UseFormRegister, UseFormWatch, UseFormSetValue } from "react-hook-form";

import Grid from "@/components/Grid/Grid";
import TextField from "@/components/TextField/TextField";
import Divider from "../../../atoms/Divider";
import Dropdown from "@/components/Dropdown/Dropdown";
import DropdownMulti from "@/components/DropdownMulti/DropdownMulti";
import { DropdownOption } from "@/types";
import { MerchandiseInput } from "../validation/merchandise";

interface Props {
  control: any;
  register: UseFormRegister<MerchandiseInput>;
  errors: any;
  watch: UseFormWatch<MerchandiseInput>;
  setValue: UseFormSetValue<MerchandiseInput>;
}

export default function CreateCompanySpecificProduct({ control, register, errors, watch, setValue }: Props) {
  const { t } = useTranslation();
  const [materialOptions, setMaterialOptions] = useState<DropdownOption[]>([]);
  const [conditionOptions, setConditionOptions] = useState<DropdownOption[]>([]);
  const [typeOptions, setTypeOptions] = useState<DropdownOption[]>([]);
  const [formatOptions, setFormatOptions] = useState<DropdownOption[]>([]);
  const [sizeOptions, setSizeOptions] = useState<DropdownOption[]>([]);
  const [colorOptions, setColorOptions] = useState<DropdownOption[]>([]);
  const [boxTypeOptions, setBoxTypeOptions] = useState<DropdownOption[]>([]);
  const [companiesOptions, setCompaniesOptions] = useState<DropdownOption[]>([]);
  const selectedMaterial = watch("salesItemId", 0);
  const selectedCondition = watch("conditionId", 0);

  const { onChange: onChangeCompanySpecificProductName, ...registerCompanySpecificProductName } =
    register("companySpecificProductName");

  const { data: boxTypeValues } = useGetBoxtypeValues();
  const { data: companies } = useGetAllCompanies();
  const { data: validConditions } = useGetValidConditions(selectedMaterial, {
    enabled: selectedMaterial > 0,
  });
  const { data: validTypes } = useGetValidTypes(selectedMaterial, selectedCondition, {
    enabled: selectedMaterial > 0 && selectedCondition > 0,
  });
  const { data: dropdownValues } = useGetCreateOrderValues();

  useEffect(() => {
    if (companies) {
      const companiesOptions = companies.map((boxType) => {
        return {
          value: boxType.id,
          label: boxType.companyName,
        };
      });
      setCompaniesOptions(companiesOptions);
    }
  }, [companies]);

  useEffect(() => {
    if (boxTypeValues) {
      const boxTypeOptions = boxTypeValues.map((boxType) => {
        return {
          value: boxType.id,
          label: t("common.boxtypes." + boxType.name),
        };
      });
      setBoxTypeOptions(boxTypeOptions);
    }
  }, [boxTypeValues]);

  useEffect(() => {
    if (dropdownValues) {
      if (dropdownValues.salesItems) {
        const materialOptions = dropdownValues.salesItems.map((item) => {
          return {
            value: item.id,
            label: item.value.toUpperCase(),
          };
        });
        setMaterialOptions(materialOptions);
      }

      if (dropdownValues.formats) {
        const formatOptions = dropdownValues.formats.map((format) => {
          return {
            value: format.id,
            label: t("common.formats." + format.value),
          };
        });
        setFormatOptions(formatOptions);
      }

      if (dropdownValues.sizes) {
        const sizeOptions = dropdownValues.sizes.map((size) => {
          return {
            value: size.id,
            label: size.value,
          };
        });
        setSizeOptions(sizeOptions);
      }

      if (dropdownValues.colors) {
        const colorOptions = dropdownValues.colors.map((color) => {
          return {
            value: color.id,
            label: t("common.colors." + color.value),
          };
        });
        setColorOptions(colorOptions);
      }
    }
  }, [dropdownValues]);

  useEffect(() => {
    if (validConditions) {
      const conditionOptions = validConditions.map((condition) => {
        return {
          value: condition.id,
          label: t("common.conditions." + condition.condition),
        };
      });
      setConditionOptions(conditionOptions);
    }
  }, [validConditions]);

  useEffect(() => {
    if (validTypes) {
      const typeOptions = validTypes.map((type) => {
        return {
          value: type.id,
          label: t("common.types." + type.type),
        };
      });
      setTypeOptions(typeOptions);
    }
  }, [validTypes]);

  function resetConditionAndType() {
    setValue("conditionId", 0);
    setConditionOptions([]);
    resetType();
  }
  function resetType() {
    setValue("typeId", 0);
    setTypeOptions([]);
  }

  return (
    <>
      <Grid.Row>
        <Grid.Col span={12}>
          <TextField
            id="create-contract-new-product-name"
            label={t("contracts.newProduct")}
            placeholder="Product name"
            onChange={(e: any) => onChangeCompanySpecificProductName(e)}
            errormessage={errors.companySpecificProductName?.message}
            {...registerCompanySpecificProductName}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={6}>
          <Controller
            control={control}
            name="salesItemId"
            render={({ field: { onChange } }) => (
              <Dropdown
                id="create-contract-merchandise-material"
                label={t("common.material")}
                options={materialOptions}
                errormessage={errors.salesItemId?.message}
                onChange={(e: DropdownOption) => {
                  onChange(e.value);
                  resetConditionAndType();
                }}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Controller
            control={control}
            name="conditionId"
            render={({ field: { onChange } }) => (
              <Dropdown
                id="create-contract-merchandise-condition"
                name="condition"
                label={t("common.condition")}
                options={conditionOptions}
                errormessage={errors.conditionId?.message}
                onChange={(e: DropdownOption) => {
                  onChange(e.value);
                  resetType();
                }}
              />
            )}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={6}>
          <Controller
            control={control}
            name="typeId"
            render={({ field: { onChange } }) => (
              <Dropdown
                id="create-contract-merchandise-type"
                label={t("common.type")}
                options={typeOptions}
                onChange={(e: DropdownOption) => onChange(e.value)}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Controller
            control={control}
            name="formatId"
            render={({ field: { onChange } }) => (
              <Dropdown
                id="create-contract-merchandise-format"
                label={t("common.format")}
                options={formatOptions}
                onChange={(e: DropdownOption) => onChange(e.value)}
              />
            )}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={6}>
          <Controller
            control={control}
            name="sizeId"
            render={({ field: { onChange } }) => (
              <Dropdown
                id="create-contract-merchandise-size"
                label={t("common.size")}
                options={sizeOptions}
                onChange={(e: DropdownOption) => onChange(e.value)}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Controller
            control={control}
            name="colorId"
            render={({ field: { onChange } }) => (
              <Dropdown
                id="create-contract-merchandise-colour"
                label={t("common.color")}
                options={colorOptions}
                onChange={(e: DropdownOption) => onChange(e.value)}
              />
            )}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={6}>
          <Controller
            control={control}
            name="boxTypeId"
            render={({ field: { onChange } }) => (
              <Dropdown
                id="create-contract-merchandise-box-type"
                label={t("common.boxtype")}
                options={boxTypeOptions}
                onChange={(e: DropdownOption) => onChange(e.value)}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}></Grid.Col>
      </Grid.Row>
    </>
  );
}
