import React, { useEffect, useState, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getOrder, getMyOrderList, useGetStatisticsForCompanies, useGetCreateOrderValues } from "@/services/Orders";
import { Helmet } from "react-helmet-async";
import { getOrderTypeIndicatorLarge } from "@/utils/Orders";

import OrdersTable from "../../../Tables/OrdersTable";
import UserContext from "@/contexts/UserContext";
import Grid from "@/components/Grid/Grid";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import Tabs from "@/components/Tabs/Tabs";
import Tab from "@/components/Tabs/Tab";
import OrderTabContent from "../../organisms/DashboardDrawer/DashboardOrderTabContent";
import TransactionTabContent from "../../organisms/DashboardDrawer/TransactionTabContent";
import Spinner from "@/components/Spinner/Spinner";
import DocumentsTabContent from "../../organisms/DashboardDrawer/DocumentsTabContent";
import Pagination from "@/components/Pagination/Pagination";
import Drawer from "@/components/Drawer/Drawer";
import Text from "@/components/_Reviver/atoms/Text";
import DropdownMulti from "@/components/DropdownMulti/DropdownMulti";
import CompareDates from "@/components/_Reviver/molecules/CompareDates";
import Group from "@/components/_Reviver/atoms/Group";

import "./dashboard.css";
import { DropdownOption } from "@/types";

export default function Dashboard() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;

  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState();

  // Drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [orderInDrawer, setOrderInDrawer] = useState<any>(null);
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false);

  // Charts
  const initialFromDate = new Date();
  initialFromDate.setDate(initialFromDate.getDate() - 30);
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(new Date());
  const [selectedSalesItems, setSelectedSalesItems] = useState<DropdownOption[]>([]);
  const [salesItemsOptions, setSalesItemsOptions] = useState<DropdownOption[]>([]);

  const statisticsQueryOptions = {
    enabled: selectedSalesItems.length > 0,
  };

  const { data: statisticsForCompanies } = useGetStatisticsForCompanies(
    fromDate,
    toDate,
    currentCompany.companyId,
    selectedSalesItems.map((item) => {
      return item.value;
    }),
    statisticsQueryOptions
  );
  const { data: dropdownValues } = useGetCreateOrderValues();

  useEffect(() => {
    if (dropdownValues) {
      const salesItems = dropdownValues.salesItems.map((item) => ({
        value: item.id,
        label: item.value,
      }));
      setSalesItemsOptions(salesItems);
    }
  }, [dropdownValues]);

  const memoGetMyOrderListParams = useMemo(
    () => ({
      companyid: currentCompany && currentCompany.companyId,
      includeInactive: true,
    }),
    [currentCompany]
  );

  useEffect(() => {
    async function asyncGetMyOrderList() {
      setIsLoading(true);
      //@ts-ignore
      const response = await getMyOrderList(...Object.values(memoGetMyOrderListParams));
      setTableData(response.data.orders);
      setNumberOfPages(response.data.numberOfPages);
      setIsLoading(false);
    }

    asyncGetMyOrderList();
  }, [memoGetMyOrderListParams]);

  async function asyncGetOrder(orderId: number) {
    const resp = await getOrder(orderId);
    return resp.data;
  }

  async function openDrawerHandler(orderId: number) {
    setOrderInDrawer(null);
    setIsDrawerOpen(true);
    setIsLoadingDrawer(true);
    const order = await asyncGetOrder(orderId);

    setOrderInDrawer(order);
    setIsLoadingDrawer(false);
  }

  // Paginating
  const changePangeHandler = async (pageNumber: number) => {
    setIsLoading(true);
    //@ts-ignore
    const response = await getMyOrderList(...Object.values(memoGetMyOrderListParams), pageNumber);
    setTableData(response.data.orders);
    setNumberOfPages(response.data.numberOfPages);
    setIsLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Reviver - {t("dashboard.dashboard")}</title>
        <meta name="description" content="Reviver - {t('dashboard.dashboard')}" />
      </Helmet>

      <Grid className="dashboard-container">
        <Grid.Col span={12}>
          <Grid.Col span={12}>
            <Group>
              {/* @ts-ignore, TODO: unsure about the type match here. */}
              <CompareDates fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
              <DropdownMulti
                id="statistics-salesitems"
                closeMenuOnSelect={false}
                options={salesItemsOptions}
                onChange={(e) => setSelectedSalesItems(e)}
              />
            </Group>
          </Grid.Col>

          <Grid.Col span={12}>
            {statisticsForCompanies && (
              <>
                {statisticsForCompanies.map((statistic) => (
                  <Group>
                    <Text>Number of orders: {statistic.numberOfOrders}</Text>
                    <Text>Total sales: {statistic.totalSales} </Text>
                  </Group>
                ))}
              </>
            )}
          </Grid.Col>

          <Grid.Row className="dashboard-table center">
            <Grid.Col span={12}>
              <TableHeader>{t("common.orders")}</TableHeader>
              <Spinner loading={isLoading} inline />
              {currentCompany && <OrdersTable data={tableData} onSelect={openDrawerHandler} />}
            </Grid.Col>
            <Grid.Row>
              <Grid.Col span={12} className="flex right">
                <Pagination numberOfPages={numberOfPages} neighbours={1} onClick={changePangeHandler} />
              </Grid.Col>
            </Grid.Row>
          </Grid.Row>
        </Grid.Col>
      </Grid>

      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Spinner loading={isLoadingDrawer} />
        {orderInDrawer && (
          <div className="full flex col">
            <TabsNavigation>
              <Group gap="20px" align="center">
                <SVGAsset name={getOrderTypeIndicatorLarge(orderInDrawer.orderType.id)} />
                <Text size="md" display="technical" tt="uppercase" strong={true}>
                  {t("commonOrders.orderTypes." + orderInDrawer.orderType.type)}
                </Text>
              </Group>
            </TabsNavigation>
            <Tabs>
              <Tab title={t("common.order")}>
                <OrderTabContent order={orderInDrawer} />
              </Tab>
              <Tab title={t("common.transactions")}>
                <TabInner>
                  <TransactionTabContent order={orderInDrawer} />
                </TabInner>
              </Tab>
              <Tab title={t("common.documents")}>
                <TabInner>
                  <DocumentsTabContent order={orderInDrawer} />
                </TabInner>
              </Tab>
            </Tabs>
          </div>
        )}
      </Drawer>
    </>
  );
}

const TableHeader = (props: any) => {
  const styles = {
    display: "flex",
    height: "56px",
    borderBottom: "var(--border-secondary-normal)",
    alignItems: "center",
  };
  return (
    <div style={styles}>
      <Text display="technical" size="md" tt="uppercase" strong={true}>
        {props.children}
      </Text>
    </div>
  );
};

const TabInner = (props: any) => {
  const styles = {
    padding: "20px",
  };

  return <div style={styles} {...props} />;
};

const TabsNavigation = (props: any) => {
  const styles = {
    padding: "16px 20px",
    gap: "16px",
  };

  return <div style={styles} {...props} />;
};
