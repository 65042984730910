import ContractTradeTypeEnum from "@/constants/enums/ContractTradeTypeEnum";
import ContractTypeEnum from "@/constants/enums/ContractTypeEnum";
import moment, { isDate } from "moment";
import { Company, Contract, ContractTradeType } from "@/types";

export const getContractTradeType = (contract: Contract, company: Company) =>
  contract.contractSellers.some((seller) => seller.id === company.id)
    ? ContractTradeTypeEnum.SELL
    : ContractTradeTypeEnum.BUY;

export const contractsUIConfig = (contractTradeType: ContractTradeType) =>
  ({
    buy: {
      iconName: "purchaseIndicatorLarge",
      progressColor: "green",
      companyIcon: "arrowPurchaseBlack",
      partnerIcon: "arrowSaleBlack",
    },
    sell: {
      iconName: "salesIndicatorLarge",
      progressColor: "red",
      companyIcon: "arrowPurchaseBlack",
      partnerIcon: "arrowSaleBlack",
    },
  })[contractTradeType];

export const getContractCardDetails = ({ contractSellers, contractBuyers, approvals, contractDate }: Contract) => ({
  sellerName: contractSellers[0].companyName,
  buyerName: contractBuyers[0].companyName,
  allApproved:
    contractSellers.every((seller) => approvals.some((company) => company.id === seller.id)) &&
    contractBuyers.every((seller) => approvals.some((company) => company.id === seller.id)),
  createdDate: isDate(new Date(contractDate)) ? new Date(contractDate) : "",
});

export const getContractPercentageTimeElapsed = (contract: Contract) => {
  const startDate = moment(contract.contractStartDate);
  const endDate = moment(contract.contractEndDate);

  return Math.round(
    (moment.duration(moment().diff(startDate)).days() / moment.duration(endDate.diff(startDate)).days()) * 100
  );
};

export const getContractProgressPercent = (contract: Contract) =>
  contract.contractType !== ContractTypeEnum.FIXED_AMOUNT
    ? contract.remainingAmount
    : getContractPercentageTimeElapsed(contract);
