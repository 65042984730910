import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Company } from "@/types";
import SettingsTable from ".";

interface CompanyAddressTableProps {
  company: Company;
  isAdmin: boolean;
  openEditAddressHandler: (index: number, array: any[]) => void;
  asyncDeleteAddress: (index: number, array: any[]) => void;
}

const CompanyAddressTable: FC<CompanyAddressTableProps> = ({
  company,
  isAdmin,
  openEditAddressHandler,
  asyncDeleteAddress,
}) => {
  const { t } = useTranslation();
  const createEditActions = (index: number) => [
    {
      label: t("settings.edit"),
      action: () => openEditAddressHandler(index, company.companyAddress),
    },
    {
      label: t("settings.delete"),
      action: () => asyncDeleteAddress(index, company.companyAddress),
    },
  ];
  const tableHeaders: string[] = [
    t("common.address"),
    t("common.postCode"),
    t("common.place"),
    t("common.country"),
    t("common.addressType"),
  ];
  const tableRows: ReactNode[][] = company?.companyAddress.map((address) => [
    address.address,
    address.postCode,
    address.place,
    address.country.name,
    t("registerCompany.addressTypes." + address.addressType),
  ]);

  return (
    <SettingsTable
      tableHeaders={tableHeaders}
      tableRows={tableRows}
      createEditActions={createEditActions}
      isAdmin={isAdmin}
    />
  );
};

export default CompanyAddressTable;
