import { useState, useContext, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "@/contexts/UserContext";
import TabWiz from "@/components/TabWizard/TabWiz";
import TabsWiz from "@/components/TabWizard/TabsWiz";
import { ContractStepTypeEnum, SalesStepTypeEnum } from "@/constants/enums/StepTypeEnum";
import { postCreateContract } from "@/services/Contracts";
import { useMutation } from "@tanstack/react-query";

import { ModalCTAs } from "@/components/_Reviver/organisms/Modal";
import OrderModal, { OrderModalContent } from "@/components/_Reviver/organisms/Modal/OrderModal";
import CTA from "@/components/_Reviver/atoms/CTA";
import ContractDetails from "./Steps/ContractDetails";
import Merchandise from "./Steps/Merchandise";
import Transport from "./Steps/Transport";
import Payment from "./Steps/Payment";
import getCreateNewContractObject from "@/structures/CreateContractStructure";
import { CreateContractDto } from "@/types";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export default function CreateContract({ isOpen, close }: Props) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const formSections = useRef<(HTMLFormElement | null)[]>([]);
  const { currentCompany } = userContext;

  const initialSteps = (n: number) =>
    Array.from({ length: n }, (_, index) => {
      return {
        key: `step${index + 1}`,
        isActive: index === 0,
        isDone: false,
      };
    });

  const [steps, setSteps] = useState(() => initialSteps(4));
  const [currentStep, setCurrentStep] = useState(0);
  const [createContractValues, setCreateContractValues] = useState(getCreateNewContractObject());

  const { mutateAsync: mutateCreateContract } = useMutation({
    mutationFn: async (data: CreateContractDto) => {
      const returnedData = await postCreateContract(data, currentCompany.companyId);
    },
    onSuccess: () => {
      close();
    },
  });

  const previousStep = () => {
    setSteps((step) =>
      step.map((x) => {
        step[currentStep].isActive = false;
        step[currentStep].isDone = false;
        step[currentStep - 1].isActive = true;
        return x;
      })
    );

    setCurrentStep((prev) => prev - 1);
  };

  const nextStep = () => {
    if (steps.length - 1 <= currentStep) {
      return;
    }
    setSteps((step) =>
      step.map((x) => {
        step[currentStep].isActive = false;
        step[currentStep].isDone = true;
        step[currentStep + 1].isActive = true;
        return x;
      })
    );

    setCurrentStep((prev) => prev + 1);
  };

  const onChangeCreateContractValues = (changed: any) => {
    setCreateContractValues({
      ...createContractValues,
      ...changed,
    });
  };

  const validateCurrentStep = () => {
    const form = formSections.current[currentStep];

    if (form) {
      form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  };

  const onFormSectionValidationSuccess = async (data: any) => {
    if (currentStep === steps.length - 1) {
      //create contract
      const newContract: CreateContractDto = {
        name: createContractValues.name,
        description: createContractValues.description,
        contractBuyers:
          createContractValues.contractBuyers.length === 0
            ? [currentCompany.companyId]
            : createContractValues.contractBuyers,
        contractSellers:
          createContractValues.contractSellers.length === 0
            ? [currentCompany.companyId]
            : createContractValues.contractSellers,
        contractDate: createContractValues.contractDate,
        contractStartDate: createContractValues.contractStartDate,
        contractEndDate: createContractValues.contractEndDate,
        companySpecificProductId: parseInt(createContractValues.companySpecificProductId),
        useTransportServices: createContractValues.useTransportServices,
        amountOnContract: parseInt(createContractValues.amountOnContract),
        agreedPricePrKg: createContractValues.agreedPricePrKg,
        contractPaymentId: createContractValues.contractPaymentId,
      };
      await mutateCreateContract(newContract);
    } else {
      nextStep();
    }
  };

  return (
    <>
      <OrderModal svgAsset="contract" headerText={t("contracts.newContract")} open={isOpen} onClose={close}>
        <OrderModalContent>
          <TabsWiz>
            <TabWiz isActive={steps[0].isActive} isDone={steps[0].isDone} stepTitle={t("contracts.details")}>
              <ContractDetails
                ref={(el: HTMLFormElement | null) => (formSections.current[0] = el)}
                onValidationSuccess={onFormSectionValidationSuccess}
                onChangeValues={onChangeCreateContractValues}
                values={createContractValues}
              />
            </TabWiz>
            <TabWiz
              isActive={steps[1].isActive}
              isDone={steps[1].isDone}
              stepTitle={t("contracts.merchandise")}
              hasBorder={true}
            >
              <Merchandise
                ref={(el: HTMLFormElement | null) => (formSections.current[1] = el)}
                onValidationSuccess={onFormSectionValidationSuccess}
                onChangeValues={onChangeCreateContractValues}
                values={createContractValues}
              />
            </TabWiz>
            <TabWiz
              isActive={steps[2].isActive}
              isDone={steps[2].isDone}
              stepTitle={t("contracts.transport")}
              hasBorder={true}
            >
              <Transport
                ref={(el: HTMLFormElement | null) => (formSections.current[2] = el)}
                onValidationSuccess={onFormSectionValidationSuccess}
                onChangeValues={onChangeCreateContractValues}
                values={createContractValues}
              />
            </TabWiz>
            <TabWiz
              isActive={steps[3].isActive}
              isDone={steps[3].isDone}
              stepTitle={t("contracts.payment")}
              hasBorder={true}
            >
              <Payment
                ref={(el: HTMLFormElement | null) => (formSections.current[3] = el)}
                onValidationSuccess={onFormSectionValidationSuccess}
                onChangeValues={onChangeCreateContractValues}
                values={createContractValues}
              />
            </TabWiz>
          </TabsWiz>
        </OrderModalContent>
        <ModalCTAs>
          <CTA id="cancel-create-contract" intent="tertiary" onClick={() => close()}>
            {t("common.cancel")}
          </CTA>
          {currentStep > 0 && (
            <CTA id="back-create-sales-order" intent="secondary" size="lg" onClick={previousStep}>
              {t("common.back")}
            </CTA>
          )}
          <CTA id="next-create-sales-order" intent="primary" size="lg" onClick={validateCurrentStep}>
            {currentStep !== ContractStepTypeEnum.PAYMENT ? t("common.next") : t("commonOrders.createOrder")}
          </CTA>
        </ModalCTAs>
      </OrderModal>
    </>
  );
}
