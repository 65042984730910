import Joi from "joi";

export function companySpecificProductSchema(translation) {
  const t = translation;

  return Joi.object({
    companySpecificProductName: Joi.string()
      .required()
      .messages({
        "string.required": t("validation.companySpecificProductNameRequired"),
        "string.empty": t("validation.companySpecificProductNameRequired"),
      }),
    salesItemId: Joi.number()
      .required()
      .custom((value, helpers) => {
        if (value <= 0) {
          return helpers.message(t("validation.salesItemRequired"));
        }
        return value;
      })
      .messages({
        "number.base": t("validation.salesItemRequired"),
        "any.required": t("validation.salesItemRequired"),
      }),
    conditionId: Joi.number()
      .required()
      .custom((value, helpers) => {
        if (value <= 0) {
          return helpers.message(t("validation.conditionRequired"));
        }
        return value;
      })
      .messages({
        "number.base": t("validation.conditionRequired"),
        "any.required": t("validation.conditionRequired"),
      }),
  }).unknown();
}
