import { axios, APIBASE_REVIVERUSERS } from "./ApiConfig";
import { useQuery } from '@tanstack/react-query';
import { Company, CreateCompanySpecificProductDto, CompanySpecificProduct } from "@/types";

export const getCompanyById = async (companyId: any) => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/${companyId}`);

export const getAllCompanies = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/all`);

export const useGetAllCompanies = (options?: any) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery<Company[]>({
    queryKey: ['getAllCompanies'],
    queryFn: async () => {
      const result = await getAllCompanies();
      return result.data.companies;
    },
    ...queryOptions,
  });
}

export const getCompanyTeam = async (companyId: any) => axios.get(`${APIBASE_REVIVERUSERS}api/company/${companyId}/team`);

export const getTransportCompanies = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/all/transport`);

export const getCompanyByIdPublic = async (companyId: any) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/get/${companyId}/public`);

export const getCompanyCreditById = async (companyId: any) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/${companyId}/credit`);

export const postSaveCompanyNetwork = async (companyId: any, companies: any) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/${companyId}/saveCompanyNetwork`, companies);

export const getCompanyNetwork = async (companyId: any) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/${companyId}/getCompanyNetwork`);

export const getCompanyNetworkFull = async (companyId: any) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/get/${companyId}/allForWizard`);

export const deleteCompanySpecificProduct = async (companySpecificProductId: any) => {
  axios.delete(`${APIBASE_REVIVERUSERS}api/company/${companySpecificProductId}/deleteCompanySpecificProduct`);
};

export const postCreateCompanySpecificProduct = async (product: CreateCompanySpecificProductDto) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/saveCompanySpecificProduct`, product);

export const updateCompanySpecificProduct = async (product: CompanySpecificProduct) =>
  axios.put(`${APIBASE_REVIVERUSERS}api/company/companySpecificProduct`, product);

export const getCompanySpecificProducts = async (companyId: any) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/${companyId}/companySpecificProducts`);

export const useGetCompanySpecificProducts = (companyId: any, options?: any) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery<CompanySpecificProduct[]>({
    queryKey: ['getCompanySpecificProduct', companyId],
    queryFn: async () => {
      const result = await getCompanySpecificProducts(companyId);
      return result.data;
    },
    ...queryOptions,
  });
}