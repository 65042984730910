import { FC, ReactNode, forwardRef } from "react";
import StatusTable from ".";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import Tag from "../../atoms/Tag";
import Progress from "../../atoms/Progress";

interface Props {}

const ContractsTable: FC<Props> = () => {
  const tableHeaders: string[] = ["", "Dato", "Produktet", "Mengde", "Pris", "Status", "Fullført"];
  const tableRows: ReactNode[][] = [
    [
      <SVGAsset name="saleIndicator" />,
      "14.09.2024",
      "HDPE rør svart",
      "1,135kg",
      "12.50 kr/kg",
      <Tag status="ACTIVE">Active</Tag>,
      <Progress percent={70} contractTradeType="sell" size="xs" />,
    ],
    [
      <SVGAsset name="saleIndicator" />,
      "14.09.2024",
      "HDPE rør svart",
      "1,135kg",
      "12.50 kr/kg",
      <Tag status="ACTIVE">Active</Tag>,
      <Progress percent={70} contractTradeType="sell" size="xs" />,
    ],
  ];
  return (
    <StatusTable
      tableHeaders={tableHeaders}
      tableRows={tableRows}
      statusIndex={5}
      accordionTriggerContentIndices={[0, 1]}
      accordionBorderStyle="sell"
    />
  );
};

export default ContractsTable;
