import { useContext, FC } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import Group from "../../atoms/Group";
import Text from "../../atoms/Text";

import styles from "./styles.module.css";
import { Contract, ContractTradeType } from "@/types";
import {
  contractsUIConfig,
  getContractCardDetails,
  getContractProgressPercent,
  getContractTradeType,
} from "@/utils/Contracts";
import UserContext from "@/contexts/UserContext";
import Heading from "../../atoms/Heading";
import ContractTradeTypeEnum from "@/constants/enums/ContractTradeTypeEnum";
import Progress from "../../atoms/Progress";
import { ApprovalWarningText } from "../ContractCard";
import Stack from "../../atoms/Stack";
import CTA from "../../atoms/CTA";
import Grid from "@/components/Grid/Grid";
import TextField from "@/components/TextField/TextField";
import RadioButtonGroup from "../../molecules/RadioButtonGroup";
import Dropdown from "@/components/Dropdown/Dropdown";
import Divider from "../../atoms/Divider";

interface Props {
  backToContractsList: () => void;
  contract: Contract;
}

export default function EditContract({ contract, backToContractsList }: Props) {
  return (
    <div className={styles.editContractRoot}>
      <div className={styles.editContractInner}>
        <Group align="center">
          <SVGAsset onClick={backToContractsList} name="arrow-left" />
          <Text size="md" display="technical" tt="uppercase" strong={true}>
            Active contracts
          </Text>
        </Group>
        <EditContractHeader contract={contract} />
        <EditContractForm contract={contract} />
        <EditContractFooter contract={contract} />
      </div>
    </div>
  );
}

interface EditContractFormProps {
  contract: Contract;
}

const EditContractForm: FC<EditContractFormProps> = ({ contract }) => {
  const { t } = useTranslation();
  const contractTypeOptions = [
    { value: "BUY", label: "salgs" },
    { value: "SELL", label: "kjøps" },
  ];
  const contractTradeTypeOptions = [
    { value: "FIXED_EXPIRY", label: "Fast mengde" },
    { value: "FIXED_DATE", label: "Fast utløpsdato" },
  ];

  return (
    <form className={styles.editContractForm}>
      <Grid compact={true}>
        <Grid.Row>
          <Grid.Col span={6}>
            <TextField
              id="create-contract-name"
              label={t("contracts.contractName")}
              placeholder="Minneverdig kontrakstnavn"
              value={contract.name}
              onChange={() => {}}
              errormessage=""
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextField
              id="create-contract-description"
              label={t("contracts.contractDescription")}
              placeholder="Skriv beskrivelsen"
              value={contract.description}
              onChange={() => {}}
              errormessage=""
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={3}>
            <RadioButtonGroup
              title={t("contracts.contractTradeType")}
              name="tradeType"
              options={contractTradeTypeOptions}
              defaultValue={contractTradeTypeOptions[0].value}
              onValueChange={(value: string) => {
                console.log(value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <RadioButtonGroup
              name="contractType"
              title={t("contracts.contractType")}
              options={contractTypeOptions}
              defaultValue={contractTypeOptions[0].value}
              onValueChange={(value: string) => {
                console.log(value);
              }}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <Divider visible={false} margin={false} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={6}>
            <Dropdown
              id="create-contract-trading-partner"
              name="trading-partner"
              label={t("contracts.tradingPartner")}
              options={[]}
              value={{}}
              onChange={(e) => {}}
              errormessage=""
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-trading-partner"
              name="trading-partner"
              label={t("contracts.tradingPartner")}
              options={[]}
              value={{}}
              onChange={(e) => {}}
              errormessage=""
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-trading-partner"
              name="trading-partner"
              label={t("contracts.tradingPartner")}
              options={[]}
              value={{}}
              onChange={(e) => {}}
              errormessage=""
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-trading-partner"
              name="trading-partner"
              label={t("contracts.tradingPartner")}
              options={[]}
              value={{}}
              onChange={(e) => {}}
              errormessage=""
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-trading-partner"
              name="trading-partner"
              label={t("contracts.tradingPartner")}
              options={[]}
              value={{}}
              onChange={(e) => {}}
              errormessage=""
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-trading-partner"
              name="trading-partner"
              label={t("contracts.tradingPartner")}
              options={[]}
              value={{}}
              onChange={(e) => {}}
              errormessage=""
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-trading-partner"
              name="trading-partner"
              label={t("contracts.tradingPartner")}
              options={[]}
              value={{}}
              onChange={(e) => {}}
              errormessage=""
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <Divider margin={false} />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </form>
  );
};

interface EditContractFooterProps {
  contract: Contract;
}

const EditContractFooter: FC<EditContractFooterProps> = () => (
  <footer className={styles.editContractFooter}>
    <Group justify="flex-end">
      <CTA size="sm" onClick={() => {}} intent="affirm" tt="uppercase">
        Oppdater kontrakten
      </CTA>
      <CTA size="sm" onClick={() => {}} intent="negate">
        Slett kontrakten
      </CTA>
    </Group>
  </footer>
);

interface EditContractHeaderProps {
  contract: Contract;
}

const EditContractHeader: FC<EditContractHeaderProps> = ({ contract }) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const contractTradeType = getContractTradeType(contract, currentCompany);
  const { iconName, companyIcon, partnerIcon } = contractsUIConfig(contractTradeType);
  const { sellerName, buyerName, allApproved, createdDate } = getContractCardDetails(contract);

  return (
    <header className={styles.editContractHeader}>
      <Group justify="space-between" gap="20px" align="flex-start">
        <Group className={styles.contractStatus} justify="flex-start" align="flex-start">
          <SVGAsset name={iconName} width="36" height="36" />
          <Stack grow={true}>
            <Heading order={4} styleOrder={5} strong={true}>
              {contract.name}
            </Heading>
            {!allApproved ? (
              <ProgressWithText percent={getContractProgressPercent(contract)} contractTradeType={contractTradeType} />
            ) : (
              <ApprovalWarningText />
            )}
          </Stack>
        </Group>
        <div className={styles.contractPartners}>
          <Text size="xs" className={styles.dateCreated} color="secondary">
            {createdDate && "Opprettet " + moment(createdDate).local().format("DD.MM.YYYY")}
          </Text>
          <Group gap="20px" justify="flex-end" className={`${styles.traders} ${styles[contractTradeType]}`}>
            <Text size="lg" strong={true}>
              <SVGAsset name={companyIcon} width="18" height="18" className={styles.tradeIcon} />
              {contractTradeType === ContractTradeTypeEnum.SELL ? sellerName : buyerName}
            </Text>
            <Text size="lg" strong={true}>
              <SVGAsset name={partnerIcon} width="18" height="18" className={styles.tradeIcon} />
              {contractTradeType === ContractTradeTypeEnum.SELL ? buyerName : sellerName}
            </Text>
          </Group>
        </div>
      </Group>
    </header>
  );
};

interface ProgressWithTextProps {
  contractTradeType: ContractTradeType;
  percent: number;
}

const ProgressWithText: FC<ProgressWithTextProps> = ({ contractTradeType, percent }) => (
  <Stack align="flex-end">
    <Text
      size="xs"
      color="secondary"
      style={{ position: "absolute", marginTop: "-24px" }}
    >{`${percent}% gjennomført`}</Text>
    <Progress percent={percent} contractTradeType={contractTradeType} />
  </Stack>
);
