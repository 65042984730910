import React, { FC, useEffect, useState } from "react";
import Modal, { ModalCTAs, ModalContent } from "@/components/_Reviver/organisms/Modal";
import Text from "@/components/_Reviver/atoms/Text";
import CTA from "@/components/_Reviver/atoms/CTA";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import { resendEmailVerification } from "@/services/Login";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { HeadersContainer, SimpleHeader } from "@/components/_Reviver/molecules/Header";
import Heading from "@/components/_Reviver/atoms/Heading";

import styles from "./styles.module.css";
import Stack from "@/components/_Reviver/atoms/Stack";
import Space from "@/components/_Reviver/atoms/Space";

interface Props {
  userContext: any;
}

interface DialogOptions {
  open?: boolean;
  message?: string;
  onClose?: () => void;
}

const RegisterUserEmailSent: FC<Props> = ({ userContext }) => {
  const { t } = useTranslation();
  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({});

  useEffect(() => {
    sessionStorage.removeItem("redirection");
  });

  async function resendVerificationEmail() {
    try {
      await resendEmailVerification(userContext.token);
      setDialogOptions({
        open: true,
        message: t("common.emailSent"),
        onClose: () => setDialogOptions({ open: false }),
      });
    } catch (e) {
      setDialogOptions({
        open: true,
        message: t("common.genericErrorRetryLater"),
        onClose: () => setDialogOptions({ open: false }),
      });
    }
  }

  return (
    <>
      <Helmet>
        <title>Reviver - {t("common.verifyEmail")}</title>
        <meta name="description" content="Reviver - Verify email" />
      </Helmet>
      <Modal open={dialogOptions.open} onClose={dialogOptions?.onClose}>
        <ModalContent>
          <Text>{dialogOptions.message}</Text>
        </ModalContent>
        <ModalCTAs>
          <CTA id="btn-close-dialog" onClick={dialogOptions.onClose ?? (() => {})}>
            {t("common.ok")}
          </CTA>
        </ModalCTAs>
      </Modal>
      <HeadersContainer>
        <SimpleHeader />
      </HeadersContainer>
      <div className={styles.registerRoot}>
        <div className={styles.registerContent}>
          <figure className={styles.splashImg}>
            <img src="/assets/img/splash/polymer-melt-logo.png" alt="Reviver logo" />
          </figure>

          <div className={styles.formPanel}>
            <SVGAsset className={styles.logo} name="reviver-logo" />
            <Stack gap="30px">
              <Heading order={4} tt="uppercase">
                {t("common.emailSent")}
              </Heading>
              <Stack gap="16px">
                <Text strong={true}>{t("registerCompany.registerEmailSent")}</Text>
                <Text>{t("registerCompany.registerEmailSentDescription")}</Text>
              </Stack>
              <Space height="60px" />
              <CTA id="btn-verifyemail-resend" onClick={resendVerificationEmail} intent="primary" size="lg">
                {t("common.resendVerification")}
              </CTA>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterUserEmailSent;
