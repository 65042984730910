import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Company } from "@/types";
import SettingsTable from ".";

interface BankAccountTableProps {
  company: Company;
  isAdmin: boolean;
  openEditBankAccountHandler: (index: number, array: any[]) => void;
  asyncDeleteBankAccount: (index: number, array: any[]) => void;
}

export const BankAccountTable: FC<BankAccountTableProps> = ({
  company,
  isAdmin,
  openEditBankAccountHandler,
  asyncDeleteBankAccount,
}) => {
  const { t } = useTranslation();
  function createEditActions(index: number) {
    return [
      {
        label: t("settings.edit"),
        action: () => openEditBankAccountHandler(index, company.bankAccount),
      },
      {
        label: t("settings.delete"),
        action: () => asyncDeleteBankAccount(index, company.bankAccount),
      },
    ];
  }
  const tableHeaders: string[] = [t("settings.bankAccountName"), t("settings.bankAccountNr"), "IBAN"];
  const tableRows: ReactNode[][] = company?.bankAccount.map((account) => [
    account.name,
    account.accountNo,
    account.iban,
  ]);

  return (
    <SettingsTable
      tableHeaders={tableHeaders}
      tableRows={tableRows}
      createEditActions={createEditActions}
      accordionTriggerContentIndex={0}
      isAdmin={isAdmin}
    />
  );
};

export default BankAccountTable;
