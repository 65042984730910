import { FC } from "react";
import { CompanyOption } from "@/types";
import { TableIconsLockedNetwork } from "../../atoms/Icons/SettingsTableIcons";
import styles from "./styles.module.css";

interface NetworkTableProps {
  existingNetwork: CompanyOption[];
}

const NetworkTable: FC<NetworkTableProps> = ({ existingNetwork }) => {
  return (
    <table className={`${styles.desktopTable} ${styles.displayOnMobile}`}>
      <tbody>
        {existingNetwork?.map((company: any, index: number) => (
          <tr key={index}>
            <td>{company.label}</td>
            <td>
              <TableIconsLockedNetwork />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default NetworkTable;
