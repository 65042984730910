import * as z from "zod";
import ContractTypeEnum from "@/constants/enums/ContractTypeEnum";

const MerchandiseBaseSchema = z.object({
  agreedPricePrKg: z.string({
    required_error: "Price is required",
    invalid_type_error: "Price is required",
  }),
});

const MerchandiseNewProductSchema = z.object({
  selectFromExisting: z.boolean(),
  companySpecificProductName: z
    .string()
    .min(3, "Name must be at least 3 characters")
    .max(25, "Name must not exceed 25 characters"),
  salesItemId: z.number({
    required_error: "Please select a material",
    invalid_type_error: "Please select a material",
  }),
  conditionId: z.number({
    required_error: "Please select a condition",
    invalid_type_error: "Please select a condition",
  }),
  typeId: z.number().optional().nullable().default(null),
  formatId: z.number().optional().nullable().default(null),
  sizeId: z.number().optional().nullable().default(null),
  colorId: z.number().optional().nullable().default(null),
  boxTypeId: z.number().optional().nullable().default(null),
  companySpecificProductNetwork: z.array(z.number()).optional().nullable().default([]),
});
type MerchandiseNewProduct = z.infer<typeof MerchandiseNewProductSchema>;

const MerchandiseExistingProductSchema = z.object({
  selectFromExisting: z.boolean(),
  companySpecificProductId: z.number({
    required_error: "Product is required",
    invalid_type_error: "Product is required",
  }),
});

const ContractEndByDateSchema = z.object({
  contractEndType: z.string(),
  contractStartDate: z.date({
    required_error: "Start date is required",
    invalid_type_error: "Start date is required",
  }),
  contractEndDate: z.date({
    required_error: "End date is required",
    invalid_type_error: "End date is required",
  }),
});

const ContractEndByAmountSchema = z.object({
  contractEndType: z.string(),
  amountOnContract: z.string({
    required_error: "Amount select a condition",
    invalid_type_error: "Amount select a condition",
  }),
});

const ContractSchema = z.discriminatedUnion("contractEndType", [
  ContractEndByDateSchema.extend({ contractEndType: z.literal(ContractTypeEnum.FIXED_EXPIRY) }),
  ContractEndByAmountSchema.extend({ contractEndType: z.literal(ContractTypeEnum.FIXED_AMOUNT) }),
]);

const MerchandiseProductSchema = z.discriminatedUnion("selectFromExisting", [
  MerchandiseExistingProductSchema.extend({ selectFromExisting: z.literal(true) }),
  MerchandiseNewProductSchema.extend({ selectFromExisting: z.literal(false) }),
]);

export const MerchandiseSchema = MerchandiseBaseSchema.and(MerchandiseProductSchema).and(ContractSchema);

export type MerchandiseInput = z.infer<typeof MerchandiseSchema>;

export function isMerchandiseNewProduct(input: unknown): input is MerchandiseNewProduct {
  if (typeof input !== "object" || input === null) return false;

  const obj = input as Record<string, unknown>;

  return (
    obj.selectFromExisting === false &&
    typeof obj.companySpecificProductName === "string" &&
    typeof obj.salesItemId === "number" &&
    typeof obj.conditionId === "number" &&
    (obj.typeId === null || typeof obj.typeId === "number" || obj.typeId === undefined) &&
    (obj.formatId === null || typeof obj.formatId === "number" || obj.formatId === undefined) &&
    (obj.sizeId === null || typeof obj.sizeId === "number" || obj.sizeId === undefined) &&
    (obj.colorId === null || typeof obj.colorId === "number" || obj.colorId === undefined) &&
    (obj.boxTypeId === null || typeof obj.boxTypeId === "number" || obj.boxTypeId === undefined)
  );
}
