import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@/components/Grid/Grid";
import { ValidationErrors } from "@/types";
import Text from "@/components/_Reviver/atoms/Text";
import Stack from "@/components/_Reviver/atoms/Stack";
import Divider from "@/components/_Reviver/atoms/Divider";
import RadioButtonGroup from "@/components/_Reviver/molecules/RadioButtonGroup";

interface Props {
  onValidationSuccess: (data: any) => void;
  onChangeValues: (changed: any) => void;
  values: any;
}

const Payment = forwardRef<HTMLFormElement, Props>(({ onValidationSuccess, onChangeValues, values }, ref) => {
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const paymentOptions = [
    { value: "transaction", label: "per transaksjon" },
    { value: "monthly", label: "per måned" },
    { value: "quarterly", label: "per kvartal" },
    { value: "yearly", label: "per år" },
  ];

  function onSubmit(data: any) {
    onValidationSuccess(data);
  }

  return (
    <>
      <form ref={ref} onSubmit={onSubmit}>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Stack gap="12px">
                <Text color="secondary" size="sm">
                  Pris
                </Text>
                <Text size="lg" strong={true}>
                  125.46 NOK per 1000kg
                </Text>
              </Stack>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <RadioButtonGroup options={paymentOptions} title="Betalingsmodus" name={"betaling"} />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={8}>
              <Text color="secondary">
                Du har valgt å betale månedlig. Alle serviceavgifter vil bli beregnet på månedlig basis, og faste
                transportkostnader gjelder per transaksjon. Ytterligere detaljer om kostnadsberegninger finner du i
                vilkårene.
              </Text>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </form>
    </>
  );
});

export default Payment;
