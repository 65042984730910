import * as z from "zod";

export const ContractDetailsSchema = z.object({
  name: z.string().min(3, "Name must be at least 3 characters").max(25, "Name must not exceed 25 characters"),
  description: z
    .string()
    .min(5, "Description must be at least 5 characters")
    .max(500, "Description must not exceed 500 characters"),
  contractDate: z.date({
    required_error: "Date is required",
    invalid_type_error: "Date is required",
  }),
  tradingPartner: z.object({ value: z.number(), label: z.string(), vatNo: z.string() }).required().nullable(),
  contractType: z.string(),
});

export type ContractDetailsInput = z.infer<typeof ContractDetailsSchema>;
