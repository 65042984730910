export const SalesStepTypeEnum = {
  ORDER_DETAILS: 0,
  IMAGE_UPLOAD: 1,
  LOCATION_DELIVERY: 2,
  PACKING: 3,
};

Object.freeze(SalesStepTypeEnum);

export const PurchaseStepTypeEnum = {
  ORDER_DETAILS: 0,
  LOCATION_DELIVERY: 1,
};

Object.freeze(PurchaseStepTypeEnum);

export const ContractStepTypeEnum = {
  CONTRACT_DETAILS: 0,
  MERCHANDISE: 1,
  TRANSPORT: 2,
  PAYMENT: 3,
};
