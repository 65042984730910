import { FC, ReactNode, forwardRef } from "react";
import styles from "./styles.module.css";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import Text from "../../atoms/Text";
import Group from "../../atoms/Group";

interface Props {
  tableHeaders: ReactNode[];
  tableRows: ReactNode[][];
  accordionTriggerContentIndices: number[];
  statusIndex: number;
  lastChildIsCta?: boolean;
  accordionBorderStyle: "buy" | "sell" | "warn";
}

const StatusTable: FC<Props> = ({
  tableHeaders,
  tableRows,
  accordionTriggerContentIndices,
  statusIndex,
  lastChildIsCta,
  accordionBorderStyle,
}) => {
  const filterMobileCells = (i: number) =>
    i !== statusIndex && !accordionTriggerContentIndices.some((index) => index === i);

  return (
    <div className={styles.statusTableRoot}>
      <table className={styles.desktopTable}>
        <thead>
          <tr>
            {tableHeaders.map((th, i) => (
              <th key={"th" + i}>{th}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRows.map((tr, i) => (
            <tr key={i}>
              {tr.map((td, i) => (
                <td key={"td" + i}>{td}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Accordion.Root type="single" defaultValue="item-0" collapsible className={styles.mobileAccordions}>
        {tableRows.map((tr, i) => (
          <Accordion.Item
            value={"item-" + i}
            key={i}
            className={`${styles.accordionItem} ${styles[accordionBorderStyle]}`}
          >
            <AccordionTrigger className={styles.accordionTrigger}>
              <Group justify="space-between" className={styles.triggerContent}>
                <Group>{accordionTriggerContentIndices.map((index) => tr[index])}</Group>
                <span>{tr[statusIndex]}</span>
              </Group>
            </AccordionTrigger>

            <Accordion.Content className={styles.accordionContent}>
              <ul className={styles.mobileTableRow}>
                {tr
                  .filter((_, i) => filterMobileCells(i))
                  .filter((_, i, arr) => (lastChildIsCta ? i < arr.length - 1 : true))
                  .map((td, j) => (
                    <li className={styles.mobileTableCell} key={j}>
                      <Group justify="space-between">
                        <Text span={true} size="sm" display="technical" tt="uppercase" strong={true} key={"th" + i}>
                          {tableHeaders.filter((_, i) => filterMobileCells(i))[j]}
                        </Text>
                        <Text span={true} key={"td" + j}>
                          {td}
                        </Text>
                      </Group>
                    </li>
                  ))}
                {lastChildIsCta && (
                  <li className={`${styles.mobileTableCell} ${styles.mobileCtaTableCell}`} key="cta-cell">
                    {tr[tr.length - 1]}
                  </li>
                )}
              </ul>
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </div>
  );
};

const AccordionTrigger = forwardRef(({ children, className }: any) => (
  <Accordion.Header>
    <Accordion.Trigger className={className}>
      {children}
      <ChevronDownIcon aria-hidden className={styles.toggleIcon} />
    </Accordion.Trigger>
  </Accordion.Header>
));

export default StatusTable;
