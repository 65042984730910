import React, { useEffect, useState, useMemo, useContext } from "react";
import Moment from "react-moment";
import Grid from "@/components/Grid/Grid";
import { useTranslation } from "react-i18next";
import {
  postFilteredOrders,
  getOrdersForCompany,
  getOrder,
  postCreateOrder,
  getFilters,
  postReleaseReservation,
  getOrderAmounts,
  deleteOrder,
  postCalculateTransportPrice,
  getBoxtypeValues,
} from "@/services/Orders";
import { get } from "lodash";
import { Helmet } from "react-helmet-async";
import { calcMinAmountOfBoxes } from "@/utils/TransportUtil";
import { validateFieldsHelper } from "@/utils/ValidationHelper";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import ActiveOrders from "@/components/_Reviver/molecules/ActiveOrders";
import UserContext from "@/contexts/UserContext";
import Filters from "@/components/Filters/Filters";
import Spinner from "@/components/Spinner/Spinner";
import Dropdown2 from "@/components/Dropdown2/Dropdown2";
import OrderTypeEnum from "@/constants/enums/OrderTypeEnum";
import Pagination from "@/components/Pagination/Pagination";
import Cookie from "universal-cookie";
import CollapsibleFilter from "@/components/Filters/CollapsibleFilter";
import Checkbox from "@/components/Checkbox/Checkbox";
import Modal, { ModalCTAs, ModalContent } from "../../organisms/Modal";
import Text from "@/components/_Reviver/atoms/Text";
import Tag from "@/components/_Reviver/atoms/Tag";
import EmptySearchContent from "@/components/_Reviver/molecules/EmptySearchContent";
import CTA from "@/components/_Reviver/atoms/CTA";
import { saleOrPurchase } from "@/utils/Orders";
import { differentAddressValidationSchema } from "../../organisms/CreateOrder/validation/DifferentAddressValidationSchema";
import CreateOrder from "@/components/_Reviver/organisms/CreateOrder/CreateOrder";
import Drawer from "./Drawer";
import EditOrder from "@/components/_Reviver/organisms/EditOrder/EditOrder";

import "./Trade.css";
import { TradeHeader } from "@/components/_Reviver/molecules/SubHeader";
import { AmountOption, Order, ValidationErrors } from "types";

const UPDATE_INTERVAL = 20000;

interface Props {
  setUserContext: any;
}

export interface PurchaseOrSellFromDrawerModalConfig {
  isOpen: boolean;
  text: string;
  orderIdToCancel: number;
  header?: string;
  amount?: string;
  salesitem?: any;
  text2?: string;
  text3?: string;
  price?: string;
  currency?: string;
}

interface PaginationInfo {
  numberOfPages: number;
}

export default function Trade(props: Props) {
  const { t } = useTranslation();
  const { setUserContext } = props;
  const userContext = useContext(UserContext);
  const { currentCompany, companies } = userContext;

  //  Drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [orderInDrawer, setOrderInDrawer] = useState<Order>();
  const [selectedAmountDrawer, setSelectedAmountDrawer] = useState<AmountOption>();
  const [amountOptionsDrawer, setAmountOptionsDrawer] = useState<AmountOption[]>([]);
  const [selectedCountryDrawer, setSelectedCountryDrawer] = useState(null);
  const [addressDrawer, setAddressDrawer] = useState("");
  const [postCodeDrawer, setPostCodeDrawer] = useState("");
  const [placeDrawer, setPlaceDrawer] = useState("");
  const [boxtypeOptionsDrawer, setBoxtypeOptionsDrawer] = useState([]);
  const [selectedActiveOrderBox, setSelectedActiveOrderBox] = useState<Order>();

  useEffect(() => {
    async function asyncGetBoxTypes() {
      const availableBoxTypes = await getBoxtypeValues();
      const _boxtypeOptions = availableBoxTypes.data.boxtypes.map((boxtype: any) => ({
        value: boxtype.id,
        label: t("common.boxtypes." + boxtype.name),
        maxWeight: boxtype.maxWeight,
      }));
      setBoxtypeOptionsDrawer(_boxtypeOptions);
    }

    asyncGetBoxTypes();
  }, [t]);

  // Filter
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({ numberOfPages: 0 });
  const [tableData, setTableData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState({
    activeCompanyId: currentCompany && currentCompany.companyId,
    pageNumber: 1,
  });
  const [filterData, setFilterData] = useState();
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1194px)");
    setIsMobileView(mediaQuery.matches);

    function handleMobileChange(e: MediaQueryListEvent) {
      if (e.matches) setIsMobileView(true);
      else setIsMobileView(false);
    }
    mediaQuery.addEventListener("change", handleMobileChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMobileChange);
    };
  }, []);

  // Spinners
  const [loadingFilteredOrders, setLoadingFilteredOrders] = useState<boolean>(false);
  const [isLoadingNewPriceDrawer, setIsLoadingNewPriceDrawer] = useState<boolean>(false);
  const [isLoadingDrawer, setIsLoadingDrawer] = useState<boolean>(false);

  // Company choice (after login)
  const cookies = useMemo(() => new Cookie(), []);
  const [isCompanyChoiceOpen, setIsCompanyChoiceOpen] = useState<boolean>(false);
  const companyChoiceOptions = buildCompanyChoiceOptions();

  // Create order
  const [isChooseOrderTypeOpen, setIsChooseOrderTypeOpen] = useState<boolean>(false);
  const [purchaseOrSellFromDrawerModalConfig, setPurchaseOrSellFromDrawerModalConfig] =
    useState<PurchaseOrSellFromDrawerModalConfig>({
      isOpen: false,
      text: "",
      orderIdToCancel: 0,
    });
  const [orderCopy, setOrderCopy] = useState();
  const [isCreditPopupOpen, setIsCreditPopupOpen] = useState(false);
  const [addressValidationErrors, setAddressValidationErrors] = useState<ValidationErrors>({});

  const memoGetOrdersForCompanyParams = useMemo(
    () => ({
      companyid: currentCompany && currentCompany.companyId,
      includeInactive: false,
    }),
    [currentCompany]
  );

  const memoGetFilterDataParams = useMemo(
    () => ({
      currency: "nok",
      unit: "kg",
    }),
    []
  );

  useEffect(() => {
    let ignore = false;
    let companyInterval: any;

    async function asyncGetOrdersForCompany() {
      //@ts-ignore
      const response = await getOrdersForCompany(...Object.values(memoGetOrdersForCompanyParams));
      if (!ignore) setOrders(response.data.orders);
    }

    async function asyncGetFilterData() {
      //@ts-ignore
      const response = await getFilters(...Object.values(memoGetFilterDataParams));
      if (!ignore) setFilterData(response.data);
    }

    if (!companyInterval) {
      companyInterval = setInterval(() => {
        asyncGetOrdersForCompany();
      }, UPDATE_INTERVAL);
    }

    asyncGetOrdersForCompany();
    asyncGetFilterData();

    return () => {
      ignore = true;
      clearInterval(companyInterval);
    };
  }, [memoGetFilterDataParams, memoGetOrdersForCompanyParams]);

  useEffect(() => {
    // Effect changes list based on filter.
    let filteredInterval: any;

    async function asyncPostFilteredOrders() {
      setLoadingFilteredOrders(true);
      const response = await postFilteredOrders(filter);
      setTableData(response.data.orders);
      setPaginationInfo({ numberOfPages: response.data.numberOfPages });
      setLoadingFilteredOrders(false);
    }

    if (!filteredInterval) {
      filteredInterval = setInterval(() => {
        asyncPostFilteredOrders();
      }, UPDATE_INTERVAL);
    }

    asyncPostFilteredOrders();

    return () => {
      clearInterval(filteredInterval);
    };
  }, [filter]);

  useEffect(() => {
    // If company changes, change active company in initialFilter. Also reset paginators
    setFilter((prevValue) => {
      return {
        ...prevValue,
        activeCompanyId: currentCompany && currentCompany.companyId,
        pageNumber: 1,
      };
    });
  }, [currentCompany]);

  useEffect(() => {
    const cookie = cookies.get("choosenCompany");
    if (!cookie) {
      if (companies.companies && companies.companies.length > 1) setIsCompanyChoiceOpen(true);
    }
  }, [companies, cookies]);

  function buildCompanyChoiceOptions() {
    let userOptions: any[] = [];
    userOptions = userOptions.concat(
      [
        {
          type: "heading",
          value: t("common.header.companies"),
        },
      ],
      companies.companies.map((company: any) => {
        return {
          value: company.companyId,
          content: (
            <div className="flex align gap10">
              <SVGAsset name="company-icon" className="circle" />
              <div>{company.companyName}</div>
            </div>
          ),
        };
      })
    );
    return userOptions;
  }

  const companyOptionsChangedHandler = (item: any) => {
    const companyId = item.value;
    const currentCompany = companies.companies.find((c: any) => c.companyId === companyId);

    setUserContext({
      ...userContext,
      currentCompany,
    });

    cookies.set("choosenCompany", true, { path: "/" });

    setIsCompanyChoiceOpen(false);
  };

  async function asyncUpdateActiveOrders() {
    //@ts-ignore
    const responseOrders = await getOrdersForCompany(...Object.values(memoGetOrdersForCompanyParams));
    setOrders(responseOrders.data.orders);
  }

  async function asyncPostCreateOrder(order: Order) {
    await postCreateOrder(order);
    //Update active orders after creating a new order
    await asyncUpdateActiveOrders();
  }

  async function asyncPostReleaseReservation(orderId: number) {
    await postReleaseReservation(orderId);
  }

  async function asyncDeleteOrder(orderId: number) {
    await deleteOrder(orderId);
    await asyncUpdateActiveOrders();
  }

  async function asyncCalculateTransportPrice(
    orderId: number,
    postCode: string,
    country: any,
    place: string,
    amount: number,
    numberOfBoxes: number,
    boxTypeId: any
  ) {
    const newPrice = await postCalculateTransportPrice(
      orderId,
      postCode ? postCode : null,
      country ? country.iso3Code : null,
      place ? place : null,
      currentCompany.companyId,
      amount,
      numberOfBoxes,
      boxTypeId
    );

    return newPrice.data;
  }

  //Filter table to replicate orders 'filter values' when clicking on an active order
  const filterTableFromActiveOrdersCB = (newFilter: any) => {
    setFilter({ ...newFilter, activeCompanyId: currentCompany.companyId });
  };

  const changePageHandler = (pageNumber: number) => {
    setFilter((prevValue) => {
      return {
        ...prevValue,
        pageNumber: pageNumber,
      };
    });
  };

  async function asyncGetOrderAmounts(order: Order) {
    const response = await getOrderAmounts(order.id);
    const _amountOptions = response.data.allowedAmounts.map((amount: number) => ({
      value: amount,
      content: amount.toString(),
    }));
    return _amountOptions;
  }

  async function asyncGetOrder(orderId: number) {
    const response = await getOrder(orderId);
    return response.data;
  }

  async function openDrawerHandler(orderFromList: any) {
    setIsDrawerOpen(true);
    setIsLoadingDrawer(true);

    let orderPromise = asyncGetOrder(orderFromList?.id);
    let order = await orderPromise;
    setOrderInDrawer(order);

    if (orderFromList?.orderType?.id === OrderTypeEnum.SELL || orderFromList?.orderType?.id === OrderTypeEnum.AUCTION) {
      const options = await asyncGetOrderAmounts(orderFromList);
      setAmountOptionsDrawer(options);
      const selectedValue = options[options.length - 1];
      setSelectedAmountDrawer(selectedValue);
    }

    if (orderFromList?.orderType?.id === OrderTypeEnum.BUY) {
      // list price is based on 20kg box, so default to this in drawer TODO: boxtype enum
      const defaultBoxType = boxtypeOptionsDrawer.find((boxtype: any) => boxtype.value === 4);
      calcBoxtypeDefaultsDrawer(order.amount, defaultBoxType, false);
    }

    setIsLoadingDrawer(false);
  }

  function resetDifferentAddressFieldsDrawer() {
    setAddressDrawer("");
    setSelectedCountryDrawer(null);
    setPostCodeDrawer("");
    setPlaceDrawer("");
  }

  function changePriceInDrawer(newPrice: any) {
    if (newPrice && newPrice.status === "PriceFound" && orderInDrawer) {
      setOrderInDrawer({
        ...orderInDrawer,
        priceWithFee: newPrice.price,
        orderDelivery: newPrice.delivery,
      });
    }
  }

  async function validateDelivery(boxType: any, nrOfBoxes: number) {
    setIsLoadingNewPriceDrawer(true);

    const inputChecker: any = validateFieldsHelper(differentAddressValidationSchema(t), {
      address: addressDrawer,
      postCode: postCodeDrawer,
      place: placeDrawer,
      country: selectedCountryDrawer,
    });
    setAddressValidationErrors(inputChecker);
    if (Object.keys(inputChecker).length === 0) {
      const newPrice = await asyncCalculateTransportPrice(
        orderInDrawer?.id ?? 0,
        postCodeDrawer,
        selectedCountryDrawer,
        placeDrawer,
        orderInDrawer?.amount ?? 0,
        nrOfBoxes,
        boxType
      );
      changePriceInDrawer(newPrice);
      setAddressValidationErrors({});
    }
    setIsLoadingNewPriceDrawer(false);
  }

  async function calcBoxtypeDefaultsDrawer(orderAmount: number, boxType: any, calculateNewTransportPrice: any) {
    const minBoxNr = calcMinAmountOfBoxes(orderAmount, boxType.maxWeight);

    if (calculateNewTransportPrice) await validateDelivery(boxType.value, minBoxNr);
  }

  function onCloseDrawer() {
    setIsDrawerOpen(false);

    // Reset different address to prevent mistakes in order.
    resetDifferentAddressFieldsDrawer();
    setAddressValidationErrors({});
  }

  function confirmPurchaseSaleFromDrawer() {
    if (orderCopy) {
      asyncPostCreateOrder(orderCopy as Order);
      closePurchaseSale(false);
      setIsDrawerOpen(false);
    }
  }

  function closePurchaseSale(releaseOrderReservation: any) {
    setPurchaseOrSellFromDrawerModalConfig({
      ...purchaseOrSellFromDrawerModalConfig,
      isOpen: false,
    });
    if (releaseOrderReservation) asyncPostReleaseReservation(purchaseOrSellFromDrawerModalConfig.orderIdToCancel);
  }

  function handleActiveOrder(order: Order) {
    setSelectedActiveOrderBox(order);

    // Clear filter if order no longer selected
    if (!order) {
      filterTableFromActiveOrdersCB(null);
      return;
    }

    const filter = buildFilter(order);
    filterTableFromActiveOrdersCB(filter);
  }

  function buildFilter(order: Order) {
    if (!order) return;

    let filter = {
      salesItems: [order?.salesItem?.id],
      sizes: [order?.size?.id],
      conditions: [order?.condition?.id],
    };

    return filter;
  }

  return (
    <>
      <Helmet>
        <title>Reviver - {t("trade.trade")}</title>
        <meta name="description" content="Reviver - {t('trade.trade')}" />
      </Helmet>

      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => onCloseDrawer()}
        orderInDrawer={orderInDrawer}
        setOrderInDrawer={setOrderInDrawer}
        setIsDrawerOpen={setIsDrawerOpen}
        setIsCreditPopupOpen={setIsCreditPopupOpen}
        amountOptionsDrawer={amountOptionsDrawer}
        isLoadingDrawer={isLoadingDrawer}
        setOrderCopy={setOrderCopy}
        validateDelivery={validateDelivery}
        addressValidationErrors={addressValidationErrors}
        setAddressValidationErrors={setAddressValidationErrors}
        isLoadingNewPriceDrawer={isLoadingNewPriceDrawer}
        selectedAmountDrawer={selectedAmountDrawer}
        setSelectedAmountDrawer={setSelectedAmountDrawer}
        purchaseOrSellFromDrawerModalConfig={purchaseOrSellFromDrawerModalConfig}
        setPurchaseOrSellFromDrawerModalConfig={setPurchaseOrSellFromDrawerModalConfig}
      />

      <Modal
        open={purchaseOrSellFromDrawerModalConfig.isOpen}
        headerText={purchaseOrSellFromDrawerModalConfig.header}
        onClose={() => closePurchaseSale(true)}
      >
        <ModalContent>
          <Text>
            {purchaseOrSellFromDrawerModalConfig.text} <strong>{purchaseOrSellFromDrawerModalConfig.amount}</strong>{" "}
            {purchaseOrSellFromDrawerModalConfig.salesitem} {purchaseOrSellFromDrawerModalConfig.text2}{" "}
            <strong>
              {parseFloat(purchaseOrSellFromDrawerModalConfig.price as any).toFixed(2)}{" "}
              {purchaseOrSellFromDrawerModalConfig.currency}/kg
            </strong>{" "}
            <br /> {purchaseOrSellFromDrawerModalConfig.text3}
          </Text>
        </ModalContent>

        <ModalCTAs>
          <CTA id="create-order-cancel" variant="tertiary" onClick={() => closePurchaseSale(true)}>
            {t("common.cancel")}
          </CTA>
          <CTA id="create-order-confirm" intent="primary" onClick={() => confirmPurchaseSaleFromDrawer()}>
            {t("common.accept")}
          </CTA>
        </ModalCTAs>
      </Modal>

      <div className="trade-container">
        <Modal open={isCompanyChoiceOpen} isCloseVisible={false}>
          <div className="pad30">
            <p className="font-family-sans">{t("common.chooseCompany")}</p>
            <Dropdown2 id="user-options" options={companyChoiceOptions} onChange={companyOptionsChangedHandler} />
          </div>
        </Modal>

        <Modal
          open={isCreditPopupOpen}
          headerText={t("trade.creditTooLow")}
          onClose={() => setIsCreditPopupOpen(false)}
        >
          <ModalContent>
            <Text>{t("trade.currentCreditTooLow")}</Text>
          </ModalContent>
          <ModalCTAs>
            <CTA id="credit-ok" onClick={() => setIsCreditPopupOpen(false)}>
              {"OK"}
            </CTA>
          </ModalCTAs>
        </Modal>

        <CreateOrder
          isChooseOrderTypeOpen={isChooseOrderTypeOpen}
          setIsChooseOrderTypeOpen={setIsChooseOrderTypeOpen}
        />

        {!selectedActiveOrderBox && (
          <>
            <TradeHeader
              createNew={() => setIsChooseOrderTypeOpen(true)}
              orders={orders}
              companyName={currentCompany.companyName}
            />
            <ActiveOrders
              orders={orders}
              createNew={() => setIsChooseOrderTypeOpen(true)}
              handleActiveOrder={handleActiveOrder}
              deleteOrder={asyncDeleteOrder}
            />
          </>
        )}

        {selectedActiveOrderBox && (
          <EditOrder
            selectedActiveOrderBox={selectedActiveOrderBox}
            handleActiveOrder={handleActiveOrder}
            refreshActiveOrdersCB={asyncUpdateActiveOrders}
          />
        )}

        <Grid className="nogap">
          <Grid.Row className="filter-result">
            <Grid.Col span={2}>
              {isMobileView && (
                <CollapsibleFilter title={"FILTERE"}>
                  <Filters filters={filterData} filter={filter} setFilter={setFilter} />
                </CollapsibleFilter>
              )}
              {!isMobileView && (
                <>
                  <h3>{t("trade.filters")}</h3>
                  <Filters filters={filterData} filter={filter} setFilter={setFilter} />
                </>
              )}
            </Grid.Col>
            <Grid.Col span={10} className="result-table">
              <div>
                <div className="flex search-counter">
                  <h3>
                    <span>
                      {tableData.length} {t("trade.hits")}
                    </span>
                  </h3>
                  <Spinner loading={loadingFilteredOrders} inline />
                </div>

                {tableData.length === 0 && Object.keys(filter).length > 1 && (
                  <EmptySearchContent onClick={() => setIsChooseOrderTypeOpen(true)} />
                )}

                {tableData.length > 0 && (
                  <table className="compact">
                    <thead>
                      <tr>
                        <th scope="col" className="arrow">
                          {" "}
                        </th>
                        <th scope="col">{t("common.date")}</th>
                        <th scope="col" colSpan={2}>
                          {t("common.company")}
                        </th>
                        <th scope="col">{t("common.type")}</th>
                        <th scope="col">{t("common.class")}</th>
                        <th scope="col" colSpan={2}>
                          {t("common.amount")}
                        </th>
                        <th scope="col">{t("common.color")}</th>
                        <th scope="col">{t("common.size")}</th>
                        <th scope="col" colSpan={2}>
                          {t("commonOrders.priceInclFee")}
                        </th>
                        <th scope="col">{t("commonOrders.inclTransportation")}</th>
                        <th scope="col">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((order: any, index) => {
                        const promoted = order.promoted ? "promoted" : "";
                        const price = order.priceWithFee[currentCompany.companyCurrency];
                        return (
                          <tr
                            id={`order-row-${index}`}
                            key={`order-row-${index}`}
                            className={`clickable ${promoted}`}
                            onClick={() => openDrawerHandler(order)}
                          >
                            <td data-label=" " className="arrow">
                              <SVGAsset name={saleOrPurchase(t, order.orderType.id).svgAsset} />
                            </td>
                            <td data-label={t("common.date")}>
                              <Moment format="DD.MM.YYYY">{order.created}</Moment>
                            </td>
                            <td data-label={t("common.company")} colSpan={2}>
                              {order.sellerName}
                            </td>
                            <td data-label={t("common.type")}>{t("common.plastic." + order.salesItem.salesItem)}</td>
                            <td data-label={t("common.class")}>
                              {t("common.conditions." + order.condition.condition)}
                            </td>
                            <td data-label={t("common.amount")} colSpan={2}>
                              {order.amountDeviation > 0
                                ? order.remainingAmount + " " + order.unit.unit + " +/- " + order.amountDeviation + "%"
                                : order.remainingAmount + " " + order.unit.unit}
                            </td>
                            <td data-label={t("common.color")}>
                              {t("common.treatments." + get(order, "treatment.treatment", ""))}
                            </td>
                            <td data-label={t("common.size")}>{order.size?.size}</td>
                            <td className="price-fixed" data-label={t("commonOrders.priceInclFee")} colSpan={2}>
                              {price.toFixed(2)} {currentCompany.companyCurrency}/{order.priceUnit.unit}
                              <span className="price-actual">
                                {price} {currentCompany.companyCurrency}/{order.priceUnit.unit}
                              </span>
                            </td>
                            <td data-label={t("commonOrders.inclTransportation")}>
                              <Checkbox
                                id="fixTransportCB"
                                checked={!currentCompany.withoutTransport}
                                disabled={true}
                              />
                            </td>
                            <td>
                              <Tag status={order.orderType.type} onClick={() => openDrawerHandler(order)}>
                                {saleOrPurchase(t, order.orderType.id).buttonText}
                              </Tag>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12} className="flex right result-piginator">
              <Pagination
                numberOfPages={paginationInfo.numberOfPages || 0}
                neighbours={2}
                onClick={changePageHandler}
                reset={!!currentCompany}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
}
