import React, { useContext } from "react";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import StatusLabel from "../StatusLabel/StatusLabel";
import SVGAsset from "../SVGAsset/SVGAsset";
import UserContext from "../../contexts/UserContext";

import "./ResponsiveTable.css";
import Tag from "../_Reviver/atoms/Tag";

function OrdersTable(props) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;

  function BuildTable() {
    if (props.data) {
      let table = (
        <table className="compact">
          <thead>
            <tr>
              <th scope="col" className="icon">
                {" "}
              </th>
              <th scope="col">{t("common.date")}</th>
              <th scope="col">{t("common.type")}</th>
              <th scope="col">{t("common.amount")}</th>
              <th scope="col">{t("common.remainingAmount")}</th>
              <th scope="col">{t("common.finalAmount")}</th>
              <th scope="col">{t("dashboard.status")}</th>
              <th scope="col">{t("commonOrders.lastPrice")}</th>
            </tr>
          </thead>

          <tbody>
            {props.data.map((order, index) => {
              return (
                <tr
                  id={`order-row-${index}`}
                  key={`table-${index}`}
                  onClick={(e) => props.onSelect(order.id, e)}
                  className={"clickable " + capitalize(order.status)}
                >
                  <td className="icon">
                    {order.status === StatusLabel.labelType.RequiresAction.toUpperCase() && (
                      <SVGAsset name="yellow-exclamation" />
                    )}
                  </td>
                  <td data-label={t("common.date")}>
                    <Moment format="DD.MM.YYYY">{order.created}</Moment>
                  </td>
                  <td data-label={t("common.type")}>{t("common.plastic." + order.salesItem.salesItem)}</td>
                  <td data-label={t("common.amount")}>
                    {order.amount + " " + order.unit.unit}{" "}
                    {order.amountDeviation > 0 ? "+/- " + order.amountDeviation + "%" : null}
                  </td>
                  <td data-label={t("common.remainingAmount")}>
                    {order.remainingAmount > 0 ? order.remainingAmount + " " + order.unit.unit : "-"}
                  </td>
                  <td data-label={t("common.finalAmount")}>
                    {order.finalAmount > 0 && order.amountDeviation > 0
                      ? order.status === "AWAITING_FINAL_AMOUNT"
                        ? "-"
                        : order.finalAmount + " " + order.unit.unit
                      : "-"}
                  </td>
                  <td data-label={t("common.settlement")}>
                    <Tag status={order.status}>{t("common.status." + order.status)}</Tag>
                  </td>
                  <td data-label={t("commonOrders.priceInclFee")}>
                    {order.price[currentCompany.companyCurrency].toFixed(2)} {currentCompany.companyCurrency}/
                    {order.priceUnit.unit}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
      return table;
    } else return null;
  }

  return <BuildTable></BuildTable>;
}
// function saleOrPurchase(t, orderTypeId) {
//   if (orderTypeId === OrderTypeEnum.SELL) {
//     return {
//       svgAsset: "saleIndicator",
//     };
//   } else if (orderTypeId === OrderTypeEnum.AUCTION) {
//     return {
//       svgAsset: "auctionIndicator",
//     };
//   } else {
//     return {
//       svgAsset: "purchaseIndicator",
//     };
//   }
// }

export default OrdersTable;
