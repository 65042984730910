import { useState, forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MerchandiseSchema, MerchandiseInput, isMerchandiseNewProduct } from "../validation/merchandise";
import { useGetCompanySpecificProducts, postCreateCompanySpecificProduct } from "@/services/Company";
import { useMutation } from "@tanstack/react-query";
import { getUserContextIds } from "@/hooks/useUserContext";
import { DropdownOption, CreateCompanySpecificProductDto } from "@/types";

import Grid from "@/components/Grid/Grid";
import Dropdown from "@/components/Dropdown/Dropdown";
import TextField from "@/components/TextField/TextField";
import RadioButtonGroup from "@/components/_Reviver/molecules/RadioButtonGroup";
import Divider from "@/components/_Reviver/atoms/Divider";
import DatePicker from "@/components/DatePicker/DatePicker";
import CreateCompanySpecificProduct from "../CreateCompanySpecificProduct";
import ContractTypeEnum from "@/constants/enums/ContractTypeEnum";

interface Props {
  onValidationSuccess: (data: any) => void;
  onChangeValues: (changed: any) => void;
  values: any;
}

const Merchandise = forwardRef<HTMLFormElement, Props>(({ onValidationSuccess, onChangeValues, values }, ref) => {
  const { t } = useTranslation();
  const [selectFromSavedProducts, setSelectFromSavedProducts] = useState(false);
  const [companySpecificProductsOptions, setCompanySpecificProductsOptions] = useState<DropdownOption[]>([]);

  const merchandiseOptions = [
    { value: "new", label: t("contracts.newProduct") },
    { value: "saved", label: t("contracts.existingProduct") },
  ];

  const userIds = getUserContextIds();
  const { data: companySpecificProducts } = useGetCompanySpecificProducts(userIds.companyId);

  useEffect(() => {
    if (companySpecificProducts) {
      const companySpecificProductsOptions = companySpecificProducts.map((item) => {
        return {
          value: item.id,
          label: item.companySpecificProductName,
        };
      });
      setCompanySpecificProductsOptions(companySpecificProductsOptions);
    }
  }, [companySpecificProducts]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<MerchandiseInput>({
    resolver: zodResolver(MerchandiseSchema),
    defaultValues: { selectFromExisting: selectFromSavedProducts },
  });

  const { onChange: onChangeAmountOnContract, ...registerAmountOnContract } = register("amountOnContract");
  const { onChange: onChangeAgreedPricePrKg, ...registerAgreedPricePrKg } = register("agreedPricePrKg");

  useEffect(() => {
    setValue("contractEndType", values.contractType);
  }, [values.contractType]);

  const { mutateAsync: mutateCreateCompanySpecificProduct } = useMutation({
    mutationFn: async (data: CreateCompanySpecificProductDto) => {
      const tradePartnerCompany =
        values.contractBuyers.length > 0 ? values.contractBuyers[0] : values.contractSellers[0];
      data.companySpecificProductNetwork = [userIds.companyId, tradePartnerCompany];
      const returnedData = await postCreateCompanySpecificProduct(data);
      if (returnedData.status === 200) {
        onChangeValues({
          companySpecificProductId: returnedData.data,
        });
      }
    },
  });

  const onSubmit = async (data: MerchandiseInput) => {
    if (!selectFromSavedProducts) {
      const contractCounterpart =
        values.contractBuyers.length > 0 ? values.contractBuyers[0] : values.contractSellers[0];
      if (isMerchandiseNewProduct(data)) {
        const newProductObj: CreateCompanySpecificProductDto = {
          companyId: userIds.companyId,
          companySpecificProductName: data.companySpecificProductName,
          salesItemId: data.salesItemId,
          conditionId: data.conditionId,
          typeId: data.typeId,
          formatId: data.formatId,
          sizeId: data.sizeId,
          colorId: data.colorId,
          boxTypeId: data.boxTypeId,
          companySpecificProductNetwork: [userIds.companyId, contractCounterpart],
        };
        await mutateCreateCompanySpecificProduct(newProductObj);
        onValidationSuccess(data);
      }
    } else {
      onValidationSuccess(data);
    }
  };

  return (
    <>
      <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
        <Grid compact={true}>
          <Grid.Row>
            <Grid.Col span={12}>
              <Controller
                control={control}
                name="selectFromExisting"
                render={({ field: { onChange } }) => (
                  <RadioButtonGroup
                    options={merchandiseOptions}
                    defaultValue={merchandiseOptions[0].value}
                    onValueChange={(val) => {
                      onChange(val === "saved" ? true : false);
                      setSelectFromSavedProducts(!selectFromSavedProducts);
                    }}
                    name={"selectFromExisting"}
                  />
                )}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          {selectFromSavedProducts ? (
            <Grid.Row>
              <Grid.Col span={12}>
                <Controller
                  control={control}
                  name="companySpecificProductId"
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      id="create-contract-saved-products"
                      name="saved-products"
                      label={t("contracts.existingProduct")}
                      options={companySpecificProductsOptions}
                      onChange={(e) => {
                        onChange(e.value);
                        onChangeValues({
                          companySpecificProductId: e.value,
                        });
                      }}
                      //@ts-ignore - TODO: fix this, need a typeguard or something. It's guarenteed that savedProduct is present with the === selectFromSavedProducts check above.
                      errormessage={errors.companySpecificProductId?.message}
                    />
                  )}
                />
              </Grid.Col>
            </Grid.Row>
          ) : (
            <>
              <Grid.Row>
                <Grid.Col span={12}>
                  <CreateCompanySpecificProduct
                    control={control}
                    register={register}
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                  />
                </Grid.Col>
              </Grid.Row>
            </>
          )}
          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          {values.contractType === ContractTypeEnum.FIXED_AMOUNT && (
            <Grid.Row>
              <Grid.Col span={6}>
                <TextField
                  id="create-contract-amountOnContract"
                  label={t("common.amount")}
                  placeholder={t("common.amount")}
                  value={values.amountOnContract}
                  //@ts-ignore - TODO: fix this, need a typeguard or something. It's guarenteed that amountOnContract is present with the === FIXED_AMOUNT check above.
                  errormessage={errors.amountOnContract?.message}
                  onChange={(e: any) => {
                    onChangeAmountOnContract(e);
                    onChangeValues({
                      amountOnContract: e.target.value,
                    });
                  }}
                  {...registerAmountOnContract}
                />
              </Grid.Col>
              <Grid.Col span={6}></Grid.Col>
            </Grid.Row>
          )}
          {values.contractType === ContractTypeEnum.FIXED_EXPIRY && (
            <Grid.Row>
              <Grid.Col span={6}>
                <Controller
                  control={control}
                  name="contractStartDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      id="create-order-startDate"
                      label={t("contracts.startDate")}
                      value={value}
                      onChange={onChange}
                      //@ts-ignore - TODO: fix this, need a typeguard or something. It's guarenteed that contractStartDate is present with the === FIXED_EXPIRY check above.
                      errormessage={errors.contractStartDate?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Controller
                  control={control}
                  name="contractEndDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      id="create-order-endDate"
                      label={t("contracts.endDate")}
                      value={value}
                      onChange={onChange}
                      //@ts-ignore - TODO: fix this, need a typeguard or something. It's guarenteed that contractEndDate is present with the === FIXED_EXPIRY check above.
                      errormessage={errors.contractEndDate?.message}
                    />
                  )}
                />
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col span={6}>
              <TextField
                id="create-contract-agreedPricePrKg"
                label={t("common.price")}
                placeholder={t("common.price")}
                value={values.agreedPricePrKg}
                errormessage={errors.agreedPricePrKg?.message}
                onChange={(e: any) => {
                  onChangeAgreedPricePrKg(e);
                  onChangeValues({
                    agreedPricePrKg: e.target.value,
                  });
                }}
                {...registerAgreedPricePrKg}
              />
            </Grid.Col>
            <Grid.Col span={6}></Grid.Col>
          </Grid.Row>
        </Grid>
      </form>
    </>
  );
});

export default Merchandise;
